import { cloneElement, ReactElement, ReactNode, useContext, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import UndoIcon from "@mui/icons-material/Undo";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/material/styles";
import { GridCheckCircleIcon } from "@mui/x-data-grid";
import { DateTime } from "luxon";
import {
  ProcessIconButton,
  StatusValue,
  useAsyncProcessStatus,
} from "@parallel/polygon/components/shared/input/status.input";
import { CommentThreadOperation, ReportEditorBlock } from "@parallel/vertex/types/assessment/assessment.report.types";
import { toLocalDate } from "@parallel/vertex/util/datetime.util";
import { formatNounWithCount } from "@parallel/vertex/util/string.util";
import { StoreContext } from "@/stores";

const ThreadEntry = ({
  title,
  date,
  onMore,
  isResolved,
  children,
}: {
  title: string;
  date: DateTime;
  onMore?: () => void;
  isResolved?: boolean;
  children?: ReactNode;
}) => (
  <Stack gap={1}>
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" gap={1} alignItems="center" px={0.5}>
        <Typography variant="body2" component="span" sx={{ color: "grey.600" }}>
          {title}
        </Typography>
        <Typography variant="label" component="span" sx={{ color: "grey.500" }}>
          {isResolved ? "Resolved " : ""}
          {date.toFormat("LLL d")}
        </Typography>
      </Stack>

      {onMore && <Button onClick={onMore}>more</Button>}
    </Stack>

    {children}
  </Stack>
);

const getCommentLabel = (status?: StatusValue) => {
  switch (status) {
    case "loading":
      return "Saving...";
    case "failure":
      return "Error";
    default:
      return "New Comment";
  }
};

const ThreadActionButton = ({
  blockId,
  operation,
  label,
  icon,
  color,
}: {
  blockId: string;
  operation: CommentThreadOperation;
  label: string;
  icon: ReactElement;
  color: "primary" | "error";
}) => {
  const {
    reportStore: { upsertCustomBlock },
  } = useContext(StoreContext);

  return (
    <ProcessIconButton
      size="small"
      process={() => upsertCustomBlock(blockId, { commentThreadOperation: operation })}
      color={color}
    >
      <Tooltip title={label}>{cloneElement(icon, { fontSize: "small" })}</Tooltip>
    </ProcessIconButton>
  );
};

const ThreadOverlayContainer = ({ children }: { children: ReactNode }) => (
  <Stack direction="row" gap={0.5} sx={{ position: "absolute", top: 4, right: 4 }}>
    {children}
  </Stack>
);

const ThreadOverlay = ({
  block,
  isParentHovered,
  startedByUserId,
}: {
  block: ReportEditorBlock;
  isParentHovered: boolean;
  startedByUserId?: string;
}) => {
  const {
    authStore: { currentUser },
  } = useContext(StoreContext);

  const { reportBlockTemplateId: blockId } = block;

  if (!isParentHovered && startedByUserId && block.custom?.commentsResolvedAt) {
    return (
      <ThreadOverlayContainer>
        <GridCheckCircleIcon color="primary" sx={{ p: 0.5 }} />
      </ThreadOverlayContainer>
    );
  }

  if (!isParentHovered || !startedByUserId) return <></>;

  return (
    <ThreadOverlayContainer>
      {block.custom?.commentsResolvedAt ? (
        <ThreadActionButton
          blockId={blockId}
          operation="open"
          label="Re-Open Thread"
          icon={<UndoIcon />}
          color="primary"
        />
      ) : (
        <ThreadActionButton
          blockId={blockId}
          operation="resolve"
          label="Resolve Thread"
          icon={<CheckIcon />}
          color="primary"
        />
      )}
      {startedByUserId === currentUser?.userId && (
        <ThreadActionButton
          blockId={blockId}
          operation="delete"
          label="Delete Thread"
          icon={<DeleteIcon />}
          color="error"
        />
      )}
    </ThreadOverlayContainer>
  );
};

const BlockCommentThread = ({
  block,
  isExpanded,
  setIsExpanded,
}: {
  block: ReportEditorBlock;
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}) => {
  const {
    authStore: { currentUser },
    reportStore: { upsertCustomBlock },
  } = useContext(StoreContext);

  const [newComment, setNewComment] = useState("");

  const [isHovered, setIsHovered] = useState(false);

  const { status: writeStatus, perform: writeComment } = useAsyncProcessStatus(() =>
    upsertCustomBlock(block.reportBlockTemplateId, { comment: newComment }).then(() => setNewComment("")),
  );

  const comments = block.custom?.comments || [];

  if (!currentUser || (comments.length === 0 && !isExpanded)) return <></>;

  const containerSx: SxProps = {
    p: 1,
    border: 1,
    borderColor: "grey.300",
    borderRadius: 1,
    gap: 2,
  };

  if (!isExpanded)
    return (
      <Stack sx={containerSx}>
        <ThreadEntry
          title={formatNounWithCount(comments.length, "Comment")}
          date={block.custom?.commentsResolvedAt || comments[0].createdAt}
          onMore={() => setIsExpanded(true)}
          isResolved={!!block.custom?.commentsResolvedAt}
        />
      </Stack>
    );

  return (
    <Stack
      sx={containerSx}
      position="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ThreadOverlay block={block} isParentHovered={isHovered} startedByUserId={comments[0]?.createdBy.userId} />
      {comments.map(comment => (
        <ThreadEntry title={comment.createdBy.fullName} date={comment.createdAt} key={comment.reportBlockCommentId}>
          <Typography variant="body2" sx={{ m: 0, px: 0.5 }}>
            {comment.text}
          </Typography>
        </ThreadEntry>
      ))}
      {(!block.custom?.commentsResolvedAt || comments.length === 0) && (
        <ThreadEntry title={currentUser.fullName} date={toLocalDate(DateTime.utc(), currentUser.timezone)}>
          <TextField
            label={getCommentLabel(writeStatus?.value)}
            value={newComment}
            onChange={e => setNewComment(e.target.value)}
            size="small"
            onKeyDown={e => e.key === "Enter" && writeComment(null)}
            sx={{ mt: 0.5 }}
          />
        </ThreadEntry>
      )}
    </Stack>
  );
};

export default BlockCommentThread;
