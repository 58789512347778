import { useContext, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { isNull, isUndefined, uniqBy } from "lodash";
import AutoCompleteInput from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import { TYPE_CATEGORY_CONFIG } from "@parallel/vertex/enums/calendar.enums";
import { TaskType } from "@parallel/vertex/types/calendar/time.types";
import { SelectOption } from "@/components/shared/input/AutoCompleteInput";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("TaskTypeInput", getLoggerContext);

const toSelectOption = (t: TaskType): SelectOption => ({
  key: t.taskTypeId,
  label: t.title,
  groupName: TYPE_CATEGORY_CONFIG[t.category].title,
});

const toCategoryOption = (t: TaskType): SelectOption => ({
  key: t.category,
  label: TYPE_CATEGORY_CONFIG[t.category].title,
});

const toTaskOption = (t: TaskType): SelectOption => ({
  key: t.taskTypeId,
  label: t.title,
});

const TaskTypeInput = ({
  providerId,
  selected,
  onSelect,
  size,
  width,
  isSplit = false,
}: {
  providerId?: string;
  selected: TaskType | null;
  onSelect: (t: TaskType | null) => void;
  size?: "small";
  width?: number;
  isSplit?: boolean;
}) => {
  const {
    apiStore: { calendarApi },
  } = useContext(StoreContext);

  const [taskTypes, setTaskTypes] = useState<TaskType[] | null>();

  useEffect(() => {
    setTaskTypes(undefined);
    calendarApi
      .searchIndirectTimeTypeCategories({ providerId })
      .then(categories => categories.flatMap(c => c.types))
      .catch(logger.handleFailure("searchIndirectTimeTypeCategories"))
      .then(setTaskTypes);
  }, [providerId]);

  const [selectedCategory, setSelectedCategory] = useState<SelectOption | null>(
    selected ? toCategoryOption(selected) : null,
  );
  const [selectedTask, setSelectedTask] = useState<SelectOption | null>(selected ? toTaskOption(selected) : null);

  useEffect(() => {
    if (!selectedCategory || (selected && selectedCategory.key !== selected.category)) {
      setSelectedTask(null);
      onSelect(null);
    }
  }, [selectedCategory]);

  if (!isSplit) {
    return (
      <AutoCompleteInput
        label="Task Type"
        options={taskTypes?.map(toSelectOption)}
        selected={selected ? toSelectOption(selected) : null}
        onSelect={option => {
          if (!option) {
            onSelect(null);
            return;
          }

          const selected = taskTypes?.find(t => t.taskTypeId === option?.key);
          selected && onSelect(selected);
        }}
        loadingText={isUndefined(taskTypes) ? "Loading" : isNull(taskTypes) ? "Error Fetching Task Types" : undefined}
        size={size}
        width={width}
      />
    );
  }

  return (
    <Stack direction="row" gap={2} width={width}>
      <AutoCompleteInput
        label="Task Category"
        options={uniqBy(taskTypes?.map(toCategoryOption), "key")}
        selected={selectedCategory}
        onSelect={setSelectedCategory}
        loadingText={
          isUndefined(taskTypes) ? "Loading" : isNull(taskTypes) ? "Error Fetching Task Categories" : undefined
        }
        size={size}
      />

      {selectedCategory && (
        <AutoCompleteInput
          label="Task Type"
          options={uniqBy(taskTypes?.filter(t => t.category === selectedCategory.key).map(toTaskOption), "key")}
          selected={selectedTask}
          onSelect={option => {
            setSelectedTask(option);

            if (!option) {
              onSelect(null);
              return;
            }

            const selected = taskTypes?.find(t => t.taskTypeId === option?.key);
            selected && onSelect(selected);
          }}
          size={size}
        />
      )}
    </Stack>
  );
};

export default TaskTypeInput;
