import { useContext } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Grid2 from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { noop } from "lodash";
import { FullGrid } from "@parallel/polygon/components/shared/layout/container";
import { displayInputFieldStyles } from "@parallel/polygon/util/style.util";
import { SingleStudentUser, StudentService } from "@parallel/vertex/types/user/student.types";
import StudentServices from "@/components/user/student/StudentServices";
import { StudentEditColumn } from "@/components/user/student/form/StudentEditForm";
import StudentInfoInput from "@/components/user/student/form/StudentInfoInput";
import { StoreContext } from "@/stores";
import { getInitialStudentParams } from "@/util/student.form.util";

const SectionHeader = ({ text, onEdit }: { text: string; onEdit?: () => void }) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    height={40}
    width="100%"
    px={1}
    borderBottom={1}
    borderColor="grey.300"
  >
    <Typography variant="h3">{text}</Typography>
    {onEdit && (
      <Button onClick={onEdit} startIcon={<EditIcon />}>
        Edit
      </Button>
    )}
  </Stack>
);

const StudentInfo = ({
  student,
  onEdit,
  onServiceArchive,
}: {
  student: SingleStudentUser;
  onEdit: (c: StudentEditColumn) => void;
  onServiceArchive: (service: StudentService) => void;
}) => {
  const {
    authStore: { currentUser },
  } = useContext(StoreContext);

  const roleAllowsEdit = currentUser && ["ADMIN", "PROVIDER"].includes(currentUser.userType);
  const canEdit = roleAllowsEdit && !student.archivedAt;

  return (
    <FullGrid container pt={2}>
      <Grid2 size={{ xs: 4 }} pr={2}>
        <SectionHeader text="Personal Info" onEdit={canEdit ? () => onEdit("info") : undefined} />
        <Stack gap={3} pt={3} sx={displayInputFieldStyles}>
          <StudentInfoInput readonly params={getInitialStudentParams(student)} setParams={noop} />
        </Stack>
      </Grid2>

      <Grid2 size={{ xs: 4 }} px={2}>
        <SectionHeader text="Parallel Services" onEdit={canEdit ? () => onEdit("services") : undefined} />
        <StudentServices student={student} onArchive={onServiceArchive} />
      </Grid2>

      <Grid2 size={{ xs: 4 }} pl={2}>
        <SectionHeader
          text="Providers"
          onEdit={canEdit && currentUser?.userType === "ADMIN" ? () => onEdit("providers") : undefined}
        />
        <Stack pt={2} pb={3}>
          {student.providers.map(provider => (
            <Stack direction="row" justifyContent="space-between" p={1} key={provider.userId}>
              <Typography variant="body1">{provider.fullName}</Typography>
              <ArrowRightIcon />
            </Stack>
          ))}
        </Stack>

        <SectionHeader text="Facilitators" onEdit={canEdit ? () => onEdit("facilitators") : undefined} />
        <Stack pt={2}>
          {student.facilitators.map(facilitator => (
            <Stack direction="row" justifyContent="space-between" p={1} key={facilitator.userId}>
              <Typography variant="body1">{facilitator.fullName}</Typography>
              <ArrowRightIcon />
            </Stack>
          ))}
        </Stack>
      </Grid2>
    </FullGrid>
  );
};

export default StudentInfo;
