import { Button, ButtonProps, Link } from "@mui/material";
import { omit } from "lodash";

export const LinkButton = (props: ButtonProps & { href: string; newTab?: boolean }) => (
  <Link
    href={props.href}
    rel={props.newTab ? "noopener noreferrer" : undefined}
    target={props.newTab ? "_blank" : undefined}
  >
    <Button {...omit(props, ["href", "newTab"])} />
  </Link>
);
