import { useContext } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import { FullBox } from "@parallel/polygon/components/shared/layout/container";
import ReportEditorSection from "@/components/report/editor/ReportEditorSection";
import { StoreContext } from "@/stores";

const ReportEditor = () => {
  const {
    reportStore: { currentReport, filteredSections },
  } = useContext(StoreContext);

  if (!currentReport) return <></>;

  return (
    <FullBox>
      <Stack width={800} p={2} gap={4} sx={{ border: 2, borderColor: "grey.300" }}>
        <Typography variant="h1">{currentReport.template.title}</Typography>

        {filteredSections.map(section => (
          <ReportEditorSection section={section} key={section.reportSectionTemplateId} />
        ))}
      </Stack>
    </FullBox>
  );
};

export default observer(ReportEditor);
