import { ReactNode, useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { init } from "@feathery/react";
import { LicenseInfo as MUILicenseInfo } from "@mui/x-license";
import { useMountEffect } from "@react-hookz/web";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import config from "@/config";
import AppointmentEventsScreen from "@/screens/AppointmentEventsScreen";
import AppointmentProgressScreen from "@/screens/AppointmentProgressScreen";
import AssessmentHomeScreen from "@/screens/AssessmentHomeScreen";
import AssessmentReportScreen from "@/screens/AssessmentReportScreen";
import CalendarScreen from "@/screens/CalendarScreen";
import CleverLoginScreen from "@/screens/CleverLoginScreen";
import FormSubmissionScreen from "@/screens/FormSubmissionScreen";
import HomeScreen from "@/screens/HomeScreen";
import LoadingScreen from "@/screens/LoadingScreen";
import LoginScreen from "@/screens/LoginScreen";
import NotFoundScreen from "@/screens/NotFoundScreen";
import ResetPasswordScreen from "@/screens/ResetPasswordScreen";
import StudentBulkUploadScreen from "@/screens/StudentBulkUploadScreen";
import StudentScreen from "@/screens/StudentScreen";
import TimeScreen from "@/screens/TimeScreen";
import UserListScreen from "@/screens/UserListScreen";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("App", getLoggerContext);

const SentryRoutes = import.meta.env.MODE === "development" ? Routes : Sentry.withSentryReactRouterV6Routing(Routes);

const Page = ({ title, children }: { title: string; children: ReactNode }) => {
  useEffect(() => {
    document.title = `${title} - Pathway`;
  }, [title]);
  return (
    <>
      {children}
      <ToastContainer />
    </>
  );
};

const _AuthPage = ({
  title,
  screen,
  noAuthScreen = <LoginScreen />,
}: {
  title: string;
  screen: ReactNode;
  noAuthScreen?: ReactNode;
}) => {
  const { authStore, calendarStore } = useContext(StoreContext);
  const { currentUser } = authStore;
  const isSignedIn = !!currentUser;

  const [isRestoringSignIn, setIsRestoringSignIn] = useState(true);

  useMountEffect(() => {
    if (!isSignedIn) {
      authStore
        .restoreSignIn()
        .catch(logger.handleFailure("restoreSignIn"))
        .finally(() => setIsRestoringSignIn(false));
    } else {
      setIsRestoringSignIn(false);
    }
  });

  useEffect(() => {
    if (!isSignedIn) return;
    calendarStore.setPayPeriod().catch(logger.handleFailure("setPayPeriod"));
  }, [isSignedIn]);

  if (!isSignedIn) screen = isRestoringSignIn ? <LoadingScreen /> : noAuthScreen;

  return <Page title={title}>{screen}</Page>;
};

const AuthPage = observer(_AuthPage);

const App = () => {
  init(config.featherySdkKey);
  MUILicenseInfo.setLicenseKey(config.muiPremiumLicenseKey);

  return (
    <BrowserRouter>
      <SentryRoutes>
        <Route path="/" element={<AuthPage title="Home" screen={<HomeScreen />} />} />
        <Route
          path="/clever"
          element={
            <Page title="Logging In">
              <CleverLoginScreen />
            </Page>
          }
        />
        <Route path="/user" element={<Navigate to="/user/student" />} />
        <Route
          path="/user/student"
          element={<AuthPage title="Students" screen={<UserListScreen listType="STUDENT" />} />}
        />
        <Route
          path="/user/student/bulk-create"
          element={<AuthPage title="Students" screen={<StudentBulkUploadScreen />} />}
        />
        <Route
          path="/user/facilitator"
          element={<AuthPage title="Facilitators" screen={<UserListScreen listType="FACILITATOR" />} />}
        />
        <Route path="/user/slpa" element={<AuthPage title="SLPAs" screen={<UserListScreen listType="SLPA" />} />} />
        <Route
          path="/user/provider"
          element={<AuthPage title="Providers" screen={<UserListScreen listType="PROVIDER" />} />}
        />
        <Route path="/user/admin" element={<AuthPage title="Admins" screen={<UserListScreen listType="ADMIN" />} />} />
        <Route path="/user/student/:studentId" element={<AuthPage title="Student" screen={<StudentScreen />} />} />
        <Route
          path="/user/student/:studentId/info"
          element={<AuthPage title="Student Info" screen={<StudentScreen currentView="info" />} />}
        />
        <Route
          path="/user/student/:studentId/goals"
          element={<AuthPage title="Student Goals" screen={<StudentScreen currentView="goals" />} />}
        />
        <Route
          path="/user/student/:studentId/progress"
          element={<AuthPage title="Student Progress" screen={<StudentScreen currentView="progress" />} />}
        />
        <Route path="/calendar" element={<AuthPage title="Calendar" screen={<CalendarScreen />} />} />
        <Route
          path="/calendar/appointment/:itemId"
          element={<AuthPage title="Appointment" screen={<CalendarScreen itemType="appointment" />} />}
        />
        <Route
          path="/calendar/appointment/:itemId/progress"
          element={<AuthPage title="Appointment Progress" screen={<AppointmentProgressScreen />} />}
        />
        <Route
          path="/calendar/appointment/:itemId/events"
          element={<AuthPage title="Appointment Events" screen={<AppointmentEventsScreen />} />}
        />
        <Route
          path="/calendar/block/:itemId"
          element={<AuthPage title="Calendar Block" screen={<CalendarScreen itemType="block" />} />}
        />
        <Route
          path="/calendar/time/:itemId"
          element={<AuthPage title="Indirect Time" screen={<CalendarScreen itemType="indirect-time" />} />}
        />
        <Route
          path="/calendar/facilitator/:userId"
          element={<AuthPage title="Facilitator Calendar" screen={<CalendarScreen userType="FACILITATOR" />} />}
        />
        <Route
          path="/calendar/slpa/:userId"
          element={<AuthPage title="SLPA Calendar" screen={<CalendarScreen userType="SLPA" />} />}
        />
        <Route
          path="/calendar/provider/:userId"
          element={<AuthPage title="Provider Calendar" screen={<CalendarScreen userType="PROVIDER" />} />}
        />
        <Route
          path="/calendar/student/:userId"
          element={<AuthPage title="Student Calendar" screen={<CalendarScreen userType="STUDENT" />} />}
        />
        <Route
          path="/form/submission/:submissionId"
          element={<AuthPage title="Form Submission" screen={<FormSubmissionScreen />} />}
        />
        <Route path="/assessment" element={<AuthPage title="Assessments" screen={<AssessmentHomeScreen />} />} />
        <Route
          path="/assessment/start/:serviceLineClientId"
          element={<AuthPage title="Create Assessment" screen={<AssessmentHomeScreen />} />}
        />
        <Route
          path="/assessment/report/:reportId"
          element={<AuthPage title="Assessment Report" screen={<AssessmentReportScreen />} />}
        />
        <Route path="/time" element={<AuthPage title="Time" screen={<TimeScreen />} />} />
        <Route
          path="/reset-password/:resetId"
          element={
            <Page title="Reset Password">
              <ResetPasswordScreen />
            </Page>
          }
        />
        <Route
          path="*"
          element={<AuthPage title="Not Found" screen={<NotFoundScreen />} noAuthScreen={<NotFoundScreen />} />}
        />
      </SentryRoutes>
    </BrowserRouter>
  );
};

export default Sentry.withProfiler(App);
