import { useContext, useState } from "react";
import CalendarViewWeekIcon from "@mui/icons-material/CalendarViewWeek";
import EventIcon from "@mui/icons-material/Event";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PrintIcon from "@mui/icons-material/Print";
import PublicIcon from "@mui/icons-material/Public";
import TimerIcon from "@mui/icons-material/Timer";
import TodayIcon from "@mui/icons-material/Today";
import ViewDayIcon from "@mui/icons-material/ViewDay";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid2 from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { capitalize, omit } from "lodash";
import { DateTime } from "luxon";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import { shortTimeZone, getValidTimezones } from "@parallel/vertex/util/datetime.util";
import CalendarWeekPicker from "@/components/calendar/CalendarWeekPicker";
import { StyledMenu, StyledMenuItem } from "@/components/shared/display/menu";
import ToggleMenuItem from "@/components/shared/input/ToggleMenuItem";
import UserInput from "@/components/user/input/UserInput";
import { CreateModalType } from "@/screens/CalendarScreen";
import { getLoggerContext, StoreContext } from "@/stores";
import { CALENDAR_PERIOD_TYPES, CalendarPeriodType } from "@/stores/calendar.store";
import { initLogger } from "@/util/logging.util";
import { useUrlNavigation } from "@/util/router.util";

const ZOOM_FACTOR_DELTA = 0.2;

const PERIOD_TYPE_ICONS = { day: <ViewDayIcon />, week: <CalendarViewWeekIcon /> };

const logger = initLogger("CalendarHeader", getLoggerContext);

const CalendarHeader = ({ onCreate }: { onCreate: (type: CreateModalType) => void }) => {
  const { authStore, calendarStore } = useContext(StoreContext);
  const { currentUser } = authStore;
  const { period, typeFilters, selectedUser } = calendarStore;

  const { navigate, query, setQuery } = useUrlNavigation();

  const now = DateTime.now().setZone(authStore.timezone);

  const [periodAnchor, setPeriodAnchor] = useState<HTMLElement>();
  const [weekPickerAnchor, setWeekPickerAnchor] = useState<HTMLElement>();

  const setPeriod = (date: DateTime) => {
    const isoDate = date.toISODate();
    if (isoDate) {
      setQuery({ ...query, date: isoDate });
    }
    setWeekPickerAnchor(undefined);
  };

  const setPeriodType = (type: CalendarPeriodType) => {
    setPeriodAnchor(undefined);
    setQuery({ ...query, period: type });
  };

  const shiftPeriod = (delta: number) => {
    const date = period.startTime.plus({ [period.type]: delta }).toISODate();
    if (!date) return;
    setQuery({ ...query, date });
  };

  const setPeriodToToday = () => setQuery(omit(query, "date"));

  const toggleWeekend = action(() => {
    const queryUpdate: Record<string, string> = { ...query };

    const newStartTime = calendarStore.isShowingWeekends
      ? period.startTime.plus({ days: 1 })
      : period.startTime.minus({ days: 1 });

    const dateString = newStartTime.toISODate();
    if (dateString) queryUpdate.date = dateString;

    if (period.type !== "week") queryUpdate.period = "week";

    calendarStore.isShowingWeekends = !calendarStore.isShowingWeekends;
    setQuery(queryUpdate);
  });

  const [createAnchor, setCreateAnchor] = useState<HTMLElement>();

  const [timezoneAnchor, setTimezoneAnchor] = useState<HTMLElement>();
  const saveTimezone = (timezone: string) => {
    setTimezoneAnchor(undefined);
    authStore.setTimezone(timezone).catch(logger.handleFailure("setTimezone"));
  };

  const canPrint = currentUser?.userType !== "ADMIN";

  const createSelected = (type: CreateModalType) => {
    setCreateAnchor(undefined);
    onCreate(type);
  };

  return (
    <Grid2 container py={3} px={3}>
      <Grid2 size={{ xs: 4 }} alignContent="center" sx={{ displayPrint: "none" }}>
        <Stack direction="row" gap={1}>
          <Stack direction="row">
            <IconButton
              onClick={() => calendarStore.zoom(ZOOM_FACTOR_DELTA * -1)}
              disabled={calendarStore.zoomFactor <= 1}
            >
              <ZoomOutIcon />
            </IconButton>
            <IconButton onClick={() => calendarStore.zoom(ZOOM_FACTOR_DELTA)}>
              <ZoomInIcon />
            </IconButton>
          </Stack>

          <Button
            variant="outlined"
            endIcon={<KeyboardArrowDownIcon />}
            sx={{ height: 40 }}
            onClick={e => setPeriodAnchor(e.currentTarget)}
          >
            {capitalize(period.type)}
          </Button>
          <StyledMenu anchorEl={periodAnchor} open={!!periodAnchor} onClose={() => setPeriodAnchor(undefined)}>
            {CALENDAR_PERIOD_TYPES.map(type => (
              <StyledMenuItem onClick={() => setPeriodType(type)} key={type}>
                <ListItemIcon>{PERIOD_TYPE_ICONS[type]}</ListItemIcon>
                <ListItemText>{capitalize(type)}</ListItemText>
              </StyledMenuItem>
            ))}
            <Divider key="divider-1" />
            {currentUser &&
              ["ADMIN", "PROVIDER"].includes(currentUser.userType) && [
                <ToggleMenuItem
                  toggle={() => calendarStore.toggleFilter("appointments")}
                  isEnabled={typeFilters.appointments}
                  text="Show Sessions"
                  key="show-sessions"
                />,
                <ToggleMenuItem
                  toggle={() => calendarStore.toggleFilter("indirectTime")}
                  isEnabled={typeFilters.indirectTime}
                  text="Show Indirect Time"
                  key="show-indirect-time"
                />,
                <ToggleMenuItem
                  toggle={() => calendarStore.toggleFilter("availabilities")}
                  isEnabled={typeFilters.availabilities}
                  text="Show Availabilities"
                  key="show-availabilities"
                />,
                <ToggleMenuItem
                  toggle={() => calendarStore.toggleFilter("focusTime")}
                  isEnabled={typeFilters.focusTime}
                  text="Show Focus Time"
                  key="show-focus-time"
                />,
                <Divider key="divider-2" />,
              ]}
            <ToggleMenuItem
              toggle={calendarStore.toggleCancelFilter}
              isEnabled={calendarStore.isShowingCancelled}
              text="Show Cancelled Sessions"
              key="show-cancelled-sessions"
            />
            <ToggleMenuItem
              toggle={toggleWeekend}
              isEnabled={calendarStore.isShowingWeekends}
              text="Show Weekends"
              key="show-weekends"
            />
            {currentUser &&
              currentUser.userType !== "SLPA" && [
                <Divider key="divider-3" />,
                <ToggleMenuItem
                  toggle={() => calendarStore.toggleFilter("consultAppointments")}
                  isEnabled={typeFilters.consultAppointments}
                  text="Show Consult Sessions"
                  key="show-consult-sessions"
                />,
                <ToggleMenuItem
                  toggle={() => calendarStore.toggleFilter("progressMeetings")}
                  isEnabled={typeFilters.progressMeetings}
                  text="Show Meetings"
                  key="show-meetings"
                />,
              ]}
          </StyledMenu>

          <Button
            startIcon={<PublicIcon />}
            sx={{ height: 40, marginLeft: 1 }}
            onClick={e => setTimezoneAnchor(e.currentTarget)}
          >
            {shortTimeZone(now)}
          </Button>
          <StyledMenu anchorEl={timezoneAnchor} open={!!timezoneAnchor} onClose={() => setTimezoneAnchor(undefined)}>
            {getValidTimezones(now).map(({ name, friendly, isCurrent }) => (
              <StyledMenuItem onClick={() => saveTimezone(name)} selected={isCurrent} key={name}>
                {friendly}
              </StyledMenuItem>
            ))}
          </StyledMenu>

          <Button
            onClick={setPeriodToToday}
            startIcon={<TodayIcon />}
            sx={{ height: 40, paddingX: 2, displayPrint: "none" }}
          >
            Today
          </Button>
        </Stack>
      </Grid2>

      <Grid2 size={{ xs: 4 }} sx={{ "@media print": { width: "100vw" } }}>
        <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
          <IconButton onClick={() => shiftPeriod(-1)}>
            <NavigateBeforeIcon />
          </IconButton>

          <Button variant="text" onClick={e => setWeekPickerAnchor(e.currentTarget)}>
            <Typography variant="h2" sx={{ color: "text.primary" }}>
              {period.startTime.toLocaleString(
                period.type === "day" ? DateTime.DATE_MED_WITH_WEEKDAY : { month: "long", year: "numeric" },
              )}
            </Typography>
          </Button>
          <StyledMenu
            anchorEl={weekPickerAnchor}
            open={!!weekPickerAnchor}
            onClose={() => setWeekPickerAnchor(undefined)}
            sx={{ marginTop: 2 }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <CalendarWeekPicker period={period} onPeriodUpdate={setPeriod} />
          </StyledMenu>

          <IconButton onClick={() => shiftPeriod(1)}>
            <NavigateNextIcon />
          </IconButton>
        </Stack>
      </Grid2>

      <Grid2 size={{ xs: 4 }} sx={{ displayPrint: "none" }}>
        <Stack direction="row" gap={2} alignItems="center" justifyContent="flex-end" sx={{ height: "100%" }}>
          {currentUser?.userType === "ADMIN" && (
            <UserInput
              label={selectedUser?.userType ? capitalize(selectedUser.userType.toLowerCase()) : "Select User"}
              selected={selectedUser}
              onSelect={selected =>
                navigate(selected ? `/calendar/${selected.userType.toLowerCase()}/${selected.userId}` : "/calendar", {
                  keepQuery: true,
                })
              }
              userTypes={["FACILITATOR", "SLPA", "PROVIDER", "STUDENT"]}
              size="small"
            />
          )}

          {canPrint && (
            <Button
              onClick={() => window.print()}
              startIcon={<PrintIcon />}
              size="medium"
              variant="outlined"
              sx={{ height: 40, displayPrint: "none" }}
            >
              Print
            </Button>
          )}

          {calendarStore.canWriteCalendar() && (
            <Button
              variant="contained"
              endIcon={<KeyboardArrowDownIcon />}
              sx={{ height: 40, displayPrint: "none" }}
              onClick={e => setCreateAnchor(e.currentTarget)}
            >
              New
            </Button>
          )}

          <StyledMenu anchorEl={createAnchor} open={!!createAnchor} onClose={() => setCreateAnchor(undefined)}>
            <StyledMenuItem onClick={() => createSelected("appointment")}>
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>
              <ListItemText>Session</ListItemText>
            </StyledMenuItem>
            <StyledMenuItem onClick={() => createSelected("availability")}>
              <ListItemIcon>
                <EventAvailableIcon />
              </ListItemIcon>
              <ListItemText>Availability</ListItemText>
            </StyledMenuItem>
            <StyledMenuItem onClick={() => createSelected("indirect-time")}>
              <ListItemIcon>
                <TimerIcon />
              </ListItemIcon>
              <ListItemText>Indirect Time</ListItemText>
            </StyledMenuItem>
            <StyledMenuItem onClick={() => createSelected("focus-time")}>
              <ListItemIcon>
                <HeadphonesIcon />
              </ListItemIcon>
              <ListItemText>Focus Time</ListItemText>
            </StyledMenuItem>
          </StyledMenu>
        </Stack>
      </Grid2>
    </Grid2>
  );
};

export default observer(CalendarHeader);
