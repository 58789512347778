import { ReactNode } from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { capitalize } from "lodash";
import { toTitleCase } from "@parallel/vertex/util/string.util";
import FormLayout, { getCancelAction, getSubmitAction, SubmitFunction } from "@/components/shared/layout/FormLayout";

const SubmitForm = <P extends object, B extends object>({
  recordName,
  operationName,
  formContent,
  params,
  validate,
  onSubmit,
  onCancel,
  confirmation,
  subheaderContent,
  skipSuccessToast,
}: {
  recordName: string;
  operationName: "update" | "create";
  formContent: ReactNode;
  params: P;
  validate: (stagedParams: P) => B | undefined;
  onSubmit: SubmitFunction<B>;
  onCancel: () => void;
  confirmation?: { prompt: () => Promise<unknown>; content?: ReactNode };
  subheaderContent?: ReactNode;
  skipSuccessToast?: boolean;
}) => {
  const submitAction = getSubmitAction(recordName, operationName, params, validate, onSubmit, skipSuccessToast);
  const primaryAction =
    confirmation && !confirmation.content
      ? {
          onClick: submitAction.onClick && confirmation.prompt,
          label: "Next",
          icon: <ArrowRightAltIcon />,
          isSync: true,
        }
      : submitAction;

  const secondaryAction = getCancelAction(onCancel);

  return (
    <FormLayout
      headerText={`${capitalize(operationName)} ${toTitleCase(recordName)}`}
      subheaderContent={subheaderContent}
      formContent={confirmation?.content || formContent}
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
    />
  );
};

export default SubmitForm;
