import { useContext, useState } from "react";
import ChecklistIcon from "@mui/icons-material/Checklist";
import { pick } from "lodash";
import { DateTime } from "luxon";
import { FullStack } from "@parallel/polygon/components/shared/layout/container";
import { searchIndirectTimeSchema } from "@parallel/vertex/types/calendar/time.types";
import { toLocalDate } from "@parallel/vertex/util/datetime.util";
import TimeDataFilters, { TimeSearchParams, TimeSettingsFilters } from "@/components/calendar/time/TimeDataFilters";
import TimeDataTable from "@/components/calendar/time/TimeDataTable";
import PrimaryLayout from "@/components/shared/layout/PrimaryLayout";
import SubHeader from "@/components/shared/layout/SubHeader";
import NotFoundScreen from "@/screens/NotFoundScreen";
import { StoreContext } from "@/stores";

const TimeScreen = () => {
  const {
    authStore: { currentUser, timezone },
  } = useContext(StoreContext);

  const today = DateTime.local({ zone: timezone });

  const [searchParams, setSearchParams] = useState<TimeSearchParams>({
    provider: null,
    startDate: today.minus({ days: 7 }),
    endDate: today,
    taskType: null,
    approvalStatus: null,
  });

  const [settingsFilters, setSettingsFilters] = useState<TimeSettingsFilters>({ showTaskDescription: true });

  const searchQuery = searchIndirectTimeSchema.safeParse({
    ...pick(searchParams, "startDate", "endDate"),
    userIds: searchParams.provider ? [searchParams.provider.key] : undefined,
    startTime: searchParams.startDate ? toLocalDate(searchParams.startDate, timezone) : null,
    endTime: searchParams.endDate ? toLocalDate(searchParams.endDate, timezone).endOf("day") : null,
    taskTypeId: searchParams.taskType?.taskTypeId,
    approvalStatus: searchParams.approvalStatus?.key,
  })?.data;

  if (currentUser?.userType !== "ADMIN") return <NotFoundScreen />;

  return (
    <PrimaryLayout headerContent={<SubHeader icon={<ChecklistIcon />} title="Time Tracking" />}>
      <FullStack>
        <TimeDataFilters
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          settingsFilters={settingsFilters}
          setSettingsFilters={setSettingsFilters}
        />
        <TimeDataTable searchQuery={searchQuery} settingsFilters={settingsFilters} />
      </FullStack>
    </PrimaryLayout>
  );
};

export default TimeScreen;
