import { intersection, isEmpty } from "lodash";
import { DateTime } from "luxon";
import { NoOverrideGlobalStaticConfig } from "../config/static.config";
import {
  ReportEditorBlock,
  ReportEditorSection,
  ReportNeedGroup,
  SingleReport,
} from "../types/assessment/assessment.report.types";
import { ExtendedAppointment, SearchAppointmentsQuery } from "../types/calendar/appointment.types";
import { filterExists } from "./collection.util";
import { getCommaList } from "./string.util";

export const getFlattenedBlocks = (report: SingleReport): ReportEditorBlock[] =>
  report.editorSections.flatMap(section =>
    section.children.flatMap(child => {
      switch (child.type) {
        case "block":
          return [child];
        case "subsection":
          return child.blocks;
      }
    }),
  );

export const isSectionVisible = (report: SingleReport, section: ReportEditorSection): boolean => {
  // section is visible if it does not include any test upload source ids
  if (isEmpty(section.testUploadSourceIds)) return true;

  // section is visible if any of the test upload source ids are present on the report
  const enabledUploadSourceIds = report.testUploads.map(u => u.reportTestUploadSourceId);
  const matchingUploadSourceIds = intersection(enabledUploadSourceIds, section.testUploadSourceIds);
  return !isEmpty(matchingUploadSourceIds);
};

export const isCustomTableEmpty = (block: ReportEditorBlock): boolean => {
  console.log(block.custom?.tableRows?.filter(r => !isEmpty(r.cellValues)));
  return isEmpty(block.custom?.tableRows?.filter(r => !isEmpty(filterExists(r.cellValues))));
};

export const getTestingSessionAppointmentQuery = (
  report: SingleReport,
  config: NoOverrideGlobalStaticConfig,
): SearchAppointmentsQuery => ({
  providerId: report.providerId,
  studentId: report.clientId,
  appointmentTypeIds: [config.testingSessionAppointmentTypeId],
  appointmentStatus: ["OCCURRED"],
});

export const getGeneralInformationData = (
  { client: { fullName, birthDate, pronouns, campus, grade }, provider }: SingleReport,
  testingSessions?: ExtendedAppointment[],
): { clientData: [string, string][]; reportData: [string, string][] } => {
  let birthDateString: string = "";
  if (birthDate) {
    const parsedBirthDate = birthDate?.toLocaleString(DateTime.DATE_FULL);
    const ageYears = Math.floor(Math.abs(birthDate.diffNow("years").years));
    birthDateString = `${parsedBirthDate} (Age ${ageYears})`;
  }
  return {
    clientData: [
      ["Student Name", fullName],
      ["Date of Birth", birthDateString],
      ["Gender Pronouns", pronouns || ""],
      ["Campus", campus?.name || ""],
      ["Grade", grade || ""],
    ],
    reportData: [
      ["Clinician", provider.fullName],
      ["Assessment Dates", testingSessions?.map(a => a.startTime.toLocaleString(DateTime.DATE_SHORT)).join(", ") || ""],
      // TODO show date report was finished + approved here once available
      ["Report Date", ""],
    ],
  };
};

export const getNeedGroupContent = (needGroup: ReportNeedGroup) => {
  const listItems = needGroup.recommendations.map(r => `<li>${r.text}</li>`);
  const content = needGroup.content || `<ul>${listItems.join("")}</ul>`;

  return {
    content,
    header: getCommaList(
      needGroup.needs.map(n => n.name),
      { delimiter: "&" },
    ),
  };
};
