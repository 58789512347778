import { arrayMove } from "@dnd-kit/sortable";
import { debounce, findIndex, set } from "lodash";
import { makeAutoObservable, runInAction } from "mobx";
import { v4 as uuid } from "uuid";
import { ClientLogger } from "@parallel/polygon/util/logging.util";
import { InterviewFormType } from "@parallel/vertex/enums/report.enums";
import {
  CreateReportBody,
  ExtendedAssessmentEnrollment,
  ExtendedReport,
  ExtendedReportTestUpload,
  ReportForm,
  ReportEditorParentSection,
  SingleReport,
  UpdateReportBlockBody,
  UpdateReportUploadBody,
  UpdateReportSectionBody,
  Need,
  ExtendedEligibility,
  CreateReportEligibilityBody,
  CreateReportNeedGroupBody,
  CustomBlock,
} from "@parallel/vertex/types/assessment/assessment.report.types";
import { ExtendedAppointment } from "@parallel/vertex/types/calendar/appointment.types";
import { FormAnswers } from "@parallel/vertex/types/form.types";
import { UpdateStudentBody } from "@parallel/vertex/types/user/student.types";
import {
  getFlattenedBlocks,
  getTestingSessionAppointmentQuery,
  isSectionVisible,
} from "@parallel/vertex/util/assessment.report.util";
import { mapExists } from "@parallel/vertex/util/collection.util";
import { CalendarAPI } from "@/api/calendar.api";
import { FormAPI } from "@/api/form.api";
import { ReportAPI, ReportFileUploadRequest } from "@/api/report.api";
import { UserAPI } from "@/api/user.api";
import config from "@/config";
import { ReportMenuSelection } from "@/util/report.util";
import { FetchStatus } from "@/util/shared.util";

export class ReportStore {
  public fetchStatus: FetchStatus = "waiting";

  public createReportEnrollment?: ExtendedAssessmentEnrollment = undefined;
  public currentReport?: SingleReport = undefined;

  public testingSessions?: ExtendedAppointment[] = undefined;
  public eligibilities?: ExtendedEligibility[] = undefined;
  public needs?: Need[] = undefined;

  public menuSelection: ReportMenuSelection = { type: "data", section: "testing-plan" };

  private editorSectionComponents: Record<
    string,
    { container: HTMLDivElement; observer: IntersectionObserver; isVisible?: boolean }
  > = {};

  public pendingDeleteEligibilityId?: string = undefined;
  public pendingDeleteNeedGroupId?: string = undefined;

  public latestUpdateKey?: string = undefined;

  constructor(
    private calendarApi: CalendarAPI,
    private formApi: FormAPI,
    private reportApi: ReportAPI,
    private userApi: UserAPI,
    private logger: ClientLogger,
  ) {
    makeAutoObservable(this);
  }

  get filteredSections(): ReportEditorParentSection[] {
    const report = this.currentReport;
    if (!report) return [];

    return mapExists(report.editorSections, section => {
      if (!isSectionVisible(report, section)) return;
      return {
        ...section,
        children: section.children.filter(c => c.type === "block" || isSectionVisible(report, c)),
      };
    });
  }

  get orderedSectionIds(): string[] {
    return this.filteredSections.flatMap(parent => {
      const subsectionIds =
        parent.title === "Recommendations"
          ? mapExists(this.currentReport?.needGroups, g => g.groupId)
          : mapExists(parent.children, c => c.type === "subsection" && c.reportSectionTemplateId);
      return [parent.reportSectionTemplateId, ...subsectionIds];
    });
  }

  get pendingDeleteEligibility() {
    if (!this.pendingDeleteEligibilityId) return;
    return this.eligibilities?.find(e => e.eligibilityId === this.pendingDeleteEligibilityId);
  }

  get pendingDeleteNeedGroup() {
    if (!this.pendingDeleteNeedGroupId) return;
    return this.currentReport?.needGroups.find(g => g.groupId === this.pendingDeleteNeedGroupId);
  }

  public loadCreateReportEnrollment = async (serviceLineClientId: string | undefined) => {
    if (!serviceLineClientId) {
      this.createReportEnrollment = undefined;
      this.fetchStatus = "finished";
      return;
    }
    if (this.createReportEnrollment?.serviceLineClientId === serviceLineClientId) {
      this.fetchStatus = "finished";
      return;
    }
    this.fetchStatus = "loading";

    const reportStudent = await this.reportApi.getAssessmentEnrollment(serviceLineClientId).catch(e => {
      this.logger.error("error resolving eligible report student", { causedBy: e, context: { serviceLineClientId } });
      return undefined;
    });
    runInAction(() => {
      this.createReportEnrollment = reportStudent;
      this.fetchStatus = reportStudent ? "finished" : "not-found";
    });
  };

  public loadReport = async (reportId: string | undefined) => {
    if (!reportId) {
      this.fetchStatus = "not-found";
      return;
    }
    this.fetchStatus = "loading";

    const report =
      this.currentReport?.reportId !== reportId
        ? await this.reportApi.getReport(reportId).catch(e => {
            this.logger.logFailure("loadReport", e, { context: { reportId } });
            return undefined;
          })
        : this.currentReport;

    let menuUpdate = this.menuSelection;

    if (report) {
      await this.loadTestingSessions(report);
      await this.loadNeeds();
      await this.loadEligibilities();

      if (report.testUploads.some(u => !!u.fileName)) {
        menuUpdate = {
          type: "editor",
          sectionId: report.editorSections[0].reportSectionTemplateId,
        };
      } else if (report.formSubmissions.testingPlan.hasAnswers) {
        menuUpdate = { type: "data", section: "tests-administered" };
      }
    }

    runInAction(() => {
      this.currentReport = report;
      this.fetchStatus = "finished";
      this.menuSelection = menuUpdate;
    });
  };

  public registerEditorSection = (sectionId: string, container: HTMLDivElement) => {
    if (this.editorSectionComponents[sectionId]) return;

    // performing delayed auto-scroll here b/c when navigating from data section to editor editor containers haven't registered yet
    if (
      this.menuSelection.type === "editor" &&
      this.menuSelection.sectionId === sectionId &&
      sectionId !== this.currentReport?.editorSections[0].reportSectionTemplateId
    ) {
      container.scrollIntoView();
    }

    const observer = new IntersectionObserver(([entry]) => {
      this.onEditorSectionVisibilityChange(sectionId, entry.isIntersecting);
    });
    observer.observe(container);

    this.editorSectionComponents[sectionId] ||= { container, observer };
  };

  public clearEditorComponents = () => {
    Object.values(this.editorSectionComponents).forEach(({ observer }) => observer.disconnect());
    this.editorSectionComponents = {};
  };

  private setSelectedEditorSection = debounce(() => {
    const sectionId = this.orderedSectionIds.find(id => this.editorSectionComponents[id]?.isVisible);
    if (!sectionId) return;
    runInAction(() => (this.menuSelection = { type: "editor", sectionId }));
  }, 500);

  private onEditorSectionVisibilityChange = (sectionId: string, isVisible: boolean) => {
    if (!this.editorSectionComponents[sectionId]) return;
    this.editorSectionComponents[sectionId].isVisible = isVisible;
    this.setSelectedEditorSection();
  };

  public setMenuSelection = (selection: ReportMenuSelection) => {
    this.menuSelection = selection;

    if (selection.type === "data") {
      this.clearEditorComponents();
      return;
    }
    const { container } = this.editorSectionComponents[selection.sectionId] || {};
    container?.scrollIntoView();
  };

  public loadTestingSessions = async (report: SingleReport) => {
    const testingSessions = await this.calendarApi.searchAppointments(
      getTestingSessionAppointmentQuery(report, config),
    );
    runInAction(() => (this.testingSessions = testingSessions.records));
  };

  public loadEligibilities = async () => {
    const eligibilities = await this.reportApi.getAllEligibilities();
    runInAction(() => (this.eligibilities = eligibilities));
  };

  public loadNeeds = async () => {
    const needs = await this.reportApi.getAllNeeds();
    this.needs = needs;
  };

  public setCreateReportEnrollment = (enrollment?: ExtendedAssessmentEnrollment) => {
    this.createReportEnrollment = enrollment;
  };

  public createReport = async (body: CreateReportBody): Promise<ExtendedReport> => {
    const report = await this.reportApi.createReport(body);
    runInAction(() => (this.currentReport = report));
    return report;
  };

  public updateCurrentReport = async (performUpdate: (reportId: string) => Promise<SingleReport>) => {
    if (!this.currentReport) return;
    const updated = await performUpdate(this.currentReport.reportId);
    runInAction(() => (this.currentReport = updated));
    return updated;
  };

  public updateCurrentReportStudent = async (body: UpdateStudentBody) => {
    const report = this.currentReport;
    if (!report) return;

    const updatedStudent = await this.userApi.updateStudent(report.clientId, body);
    runInAction(() => (this.currentReport = { ...report, client: { ...updatedStudent, clientId: report.clientId } }));
  };

  public uploadTestFiles = async (files: ReportFileUploadRequest[]) => {
    if (!this.currentReport) return;
    const uploads = await this.reportApi.uploadTestFiles(this.currentReport.reportId, files);
    this.updateCurrentTestUploads(uploads.map(u => ({ updatedUpload: u })));
  };

  public updateTestUpload = async (uploadId: string, body: UpdateReportUploadBody) => {
    if (!this.currentReport) return;
    const updatedUpload = await this.reportApi.updateTestUpload(this.currentReport.reportId, uploadId, body);
    this.updateCurrentTestUploads([{ updatedUpload, currentUploadId: uploadId }]);
  };

  private updateCurrentTestUploads = (
    updates: { updatedUpload: ExtendedReportTestUpload; currentUploadId?: string }[],
  ) => {
    updates.forEach(({ updatedUpload, currentUploadId }) => {
      if (!this.currentReport) return;

      const replaceUploadId = currentUploadId || updatedUpload.reportTestUploadId;
      const index = findIndex(this.currentReport.testUploads, u => u.reportTestUploadId === replaceUploadId);

      if (updatedUpload.archivedAt) {
        this.currentReport.testUploads.splice(index, 1);
        return;
      }

      index === -1
        ? this.currentReport.testUploads.push(updatedUpload)
        : set(this.currentReport, `testUploads.[${index}]`, updatedUpload);
    });
  };

  public upsertCustomSection = async (sectionTemplateId: string, body: UpdateReportSectionBody): Promise<void> => {
    const report = this.currentReport;
    if (!report) return;

    const customSection = await this.reportApi.upsertCustomSection(report.reportId, sectionTemplateId, body);

    const updatedSections = report.editorSections.map(section => {
      if (section.reportSectionTemplateId === sectionTemplateId) return { ...section, custom: customSection };

      const updatedChildren = section.children.map(child =>
        child.type === "subsection" && child.reportSectionTemplateId === sectionTemplateId
          ? { ...child, custom: customSection }
          : child,
      );
      return { ...section, children: updatedChildren };
    });
    runInAction(() => (this.currentReport = { ...report, editorSections: updatedSections }));
  };

  public upsertCustomBlock = async (blockTemplateId: string, body: UpdateReportBlockBody): Promise<void> => {
    const report = this.currentReport;
    if (!report) return;

    const insertUpdatedCustomBlock = (updatedCustomBlock: CustomBlock): SingleReport => {
      const updatedSections = report.editorSections.map(section => {
        const updatedChildren = section.children.map(child => {
          switch (child.type) {
            case "block":
              return child.reportBlockTemplateId === blockTemplateId ? { ...child, custom: updatedCustomBlock } : child;
            case "subsection":
              const updatedBlocks = child.blocks.map(block =>
                block.reportBlockTemplateId === blockTemplateId ? { ...block, custom: updatedCustomBlock } : block,
              );
              return { ...child, blocks: updatedBlocks };
          }
        });
        return { ...section, children: updatedChildren };
      });
      return { ...report, editorSections: updatedSections };
    };

    return this.performSequentialUpdate(() =>
      this.reportApi.upsertCustomBlock(report.reportId, blockTemplateId, body).then(insertUpdatedCustomBlock),
    );
  };

  public updateSubsectionOrder = async (movedSectionTemplateId: string, overSectionTemplateId: string) => {
    if (!this.currentReport) return;

    const parentSection = this.currentReport?.editorSections.find(parent =>
      parent.children.some(
        child => child.type === "subsection" && child.reportSectionTemplateId === movedSectionTemplateId,
      ),
    );
    if (!parentSection) return;

    const getChildIndex = (sectionTemplateId: string) =>
      findIndex(
        parentSection.children,
        c => c.type === "subsection" && c.reportSectionTemplateId === sectionTemplateId,
      );
    const fromIndex = getChildIndex(movedSectionTemplateId);
    const toIndex = getChildIndex(overSectionTemplateId);

    const updatedParentSection = {
      ...parentSection,
      children: arrayMove(parentSection.children, fromIndex, toIndex),
    };
    this.currentReport = {
      ...this.currentReport,
      editorSections: this.currentReport?.editorSections.map(s =>
        s.reportSectionTemplateId === parentSection.reportSectionTemplateId ? updatedParentSection : s,
      ),
    };

    const body = { fromIndex, toIndex };
    await this.reportApi.updateSubsectionOrder(
      this.currentReport.reportId,
      parentSection.reportSectionTemplateId,
      body,
    );
  };

  public updateNeedGroupOrder = async (movedGroupId: string, overGroupId: string) => {
    if (!this.currentReport) return;
    const { reportId, needGroups } = this.currentReport;

    const fromIndex = findIndex(needGroups, g => g.groupId === movedGroupId);
    const toIndex = findIndex(needGroups, g => g.groupId === overGroupId);

    this.currentReport = {
      ...this.currentReport,
      needGroups: arrayMove(needGroups, fromIndex, toIndex),
    };

    await this.reportApi.updateNeedGroupOrder(reportId, { fromIndex, toIndex });
  };

  public updateBlockTableCellValue = async (
    blockTemplateId: string,
    rowIndex: number,
    cellIndex: number,
    value: string,
  ): Promise<void> => {
    if (!this.currentReport) return;

    const block = getFlattenedBlocks(this.currentReport).find(b => b.reportBlockTemplateId === blockTemplateId);
    if (!block?.custom?.tableRows) return;

    const updatedTableRows = block.custom.tableRows.map((row, i) =>
      i === rowIndex
        ? { key: row.key, cellValues: row.cellValues.map((cell, j) => (j === cellIndex ? value : cell)) }
        : row,
    );
    return this.upsertCustomBlock(block.reportBlockTemplateId, { tableRows: updatedTableRows });
  };

  private performSequentialUpdate = async (update: () => Promise<SingleReport>) => {
    const updateKey = uuid();
    this.latestUpdateKey = updateKey;
    const updatedReport = await update();
    if (this.latestUpdateKey !== updateKey) return;
    runInAction(() => (this.currentReport = updatedReport));
  };

  private updateFormSubmissions = ({
    formSubmission,
    formType,
  }: {
    formSubmission: ReportForm | null;
    formType: InterviewFormType;
  }) => {
    if (!this.currentReport) return;

    set(this.currentReport, `formSubmissions.${formType}Interview`, formSubmission);
  };

  public createInitialInterviewSubmission = async (interviewType: InterviewFormType) => {
    if (!this.currentReport) return;
    return await this.reportApi
      .createInterviewNotesSubmission({
        interviewType,
        reportId: this.currentReport.reportId,
      })
      .then(formSubmission => {
        this.updateFormSubmissions({ formSubmission, formType: interviewType });
        return formSubmission;
      });
  };

  public submitInterviewSubmission = async ({
    formAnswers,
    currentFormSubmission,
    interviewType,
  }: {
    formAnswers: FormAnswers;
    currentFormSubmission: ReportForm;
    interviewType: InterviewFormType;
  }) => {
    await this.formApi.updateSubmission(currentFormSubmission.submissionId, { formAnswers }).then(formSubmission => {
      this.updateFormSubmissions({
        formSubmission: { ...formSubmission, hasAnswers: true },
        formType: interviewType,
      });
    });
  };

  public deleteInterviewSubmission = async ({
    submissionId,
    interviewType,
  }: {
    submissionId: string;
    interviewType: InterviewFormType;
  }) => {
    if (!this.currentReport) return;
    await this.formApi.deleteSubmission(submissionId).then(() => {
      this.updateFormSubmissions({ formSubmission: null, formType: interviewType });
    });
  };

  public createReportEligibility = async (eligibilityId: string, body: CreateReportEligibilityBody) => {
    if (!this.currentReport) return;
    const updatedReport = await this.reportApi.createReportEligibility(
      this.currentReport.reportId,
      eligibilityId,
      body,
    );
    runInAction(() => (this.currentReport = updatedReport));
  };

  public setPendingDeleteReportEligibilityId = (eligibilityId: string | undefined) => {
    this.pendingDeleteEligibilityId = eligibilityId;
  };

  public deletePendingReportEligibility = async () => {
    if (!this.currentReport) return;
    if (!this.pendingDeleteEligibilityId) return;
    const updatedReport = await this.reportApi.deleteReportEligibility(
      this.currentReport.reportId,
      this.pendingDeleteEligibilityId,
    );
    runInAction(() => {
      this.currentReport = updatedReport;
      this.pendingDeleteEligibilityId = undefined;
    });
  };

  public createReportNeedGroups = async (groups: CreateReportNeedGroupBody[]) => {
    const report = this.currentReport;
    if (!report) return;
    const newGroups = await this.reportApi.createReportNeedGroups(report.reportId, groups);
    const updatedReportGroups = [...report.needGroups, ...newGroups];
    runInAction(() => (this.currentReport = { ...report, needGroups: updatedReportGroups }));
  };

  public updateNeedGroupContent = async (groupId: string, content: string) => {
    const report = this.currentReport;
    if (!report) return;
    const updatedGroup = await this.reportApi.updateNeedGroupContent(report.reportId, groupId, content);
    const updatedReportGroups = report.needGroups.map(g => (g.groupId === groupId ? updatedGroup : g));
    runInAction(() => (this.currentReport = { ...report, needGroups: updatedReportGroups }));
  };

  public setPendingDeleteNeedGroupId = (groupId: string | undefined) => {
    this.pendingDeleteNeedGroupId = groupId;
  };

  public deletePendingNeedGroup = async () => {
    const report = this.currentReport;
    if (!report) return;
    if (!this.pendingDeleteNeedGroupId) return;
    await this.reportApi.deleteNeedGroup(report.reportId, this.pendingDeleteNeedGroupId);
    const updatedReportGroups = report.needGroups.filter(g => g.groupId !== this.pendingDeleteNeedGroupId);
    runInAction(() => (this.currentReport = { ...report, needGroups: updatedReportGroups }));
  };
}
