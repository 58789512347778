import { useContext } from "react";
import { SxProps, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { getPsychInfoSourceTableData } from "@parallel/vertex/util/assessment.report.source.util";
import { StoreContext } from "@/stores";

const ROW_SX: SxProps = { width: "100%", py: 1, px: 2 };

const InformationSourceTables = () => {
  const {
    reportStore: { currentReport },
  } = useContext(StoreContext);

  if (!currentReport) return <></>;

  // TODO handle SLP template
  const tableData = getPsychInfoSourceTableData(currentReport);

  return (
    <Stack gap={2}>
      {tableData.map(({ title, sourceNames }, i) => (
        <Stack key={i}>
          <Typography variant="body1" sx={{ ...ROW_SX, bgcolor: "highlight.main", fontWeight: "bold" }}>
            {title}
          </Typography>
          {sourceNames.map((sourceName, j) => (
            <Typography variant="body1" sx={{ ...ROW_SX, borderBottom: 1, borderColor: "grey.200" }} key={j}>
              {sourceName}
            </Typography>
          ))}
        </Stack>
      ))}
    </Stack>
  );
};

export default InformationSourceTables;
