import { get, isArray, isEmpty } from "lodash";
import { InterviewFormType } from "../enums/report.enums";
import { SingleReport } from "../types/assessment/assessment.report.types";
import { FormAnswers } from "../types/form.types";
import { mapExists } from "./collection.util";
import { getPossessiveNoun } from "./string.util";

type InfoSourceTableSource =
  | { type: "interview"; getName: (report: SingleReport) => string; formType: InterviewFormType }
  | { type: "testUpload"; name: string; sourceId: string }
  | { type: "testingPlan"; name: string; testingPlanMatches: string[] };

type InfoSourceTableGroup = {
  title: string;
  sources: InfoSourceTableSource[];
};

const PSYCH_INFO_SOURCE_TABLE_GROUPS: InfoSourceTableGroup[] = [
  {
    title: "Interviews",
    sources: [
      {
        type: "interview",
        getName: report => `Student Interview with ${report.client.firstName}`,
        formType: "student",
      },
      {
        type: "interview",
        getName: report => `Interview with ${getPossessiveNoun(report.client.firstName)} [parent/caregiver]`,
        formType: "caregiver",
      },
      {
        type: "interview",
        getName: report => `Interview with ${getPossessiveNoun(report.client.firstName)} teacher(s)`,
        formType: "teacher",
      },
      {
        type: "testUpload",
        name: "Diagnostic Interview for ADHD in Young People Aged 5-17 Years (Young DIVA-5)",
        sourceId: "14e39466-d111-4080-8d80-334fc5d96be2",
      },
      {
        type: "testUpload",
        name: "Behavior Assessment System for Children, Third Edition (BASC-3) Self-Report of Personality-Interview",
        sourceId: "85cc54b3-4ba1-449d-a93e-dd4776c34d90",
      },
      {
        type: "testingPlan",
        name: "Monteiro Interview Guidelines for Diagnosing the Autism Spectrum, Second Edition (MIGDAS-2)",
        testingPlanMatches: ["autism-measure~migdas-2"],
      },
    ],
  },
  {
    title: "Reviews of Records",
    sources: [
      {
        type: "testingPlan",
        name: "Review of previous psychoeducational evaluation",
        testingPlanMatches: ["non-test-measure~prev_evaluation_review"],
      },
      {
        type: "testingPlan",
        name: "Review of Individualized Education Plan IEP",
        testingPlanMatches: ["non-test-measure~iep_review"],
      },
    ],
  },
  {
    title: "Self-Report Survey Measures",
    sources: [
      {
        type: "testUpload",
        name: "Behavior Assessment System for Children, Third Edition (BASC-3) Self-Report of Personality (SRP)",
        sourceId: "638895cd-4c62-4b42-9810-8ee9516527cd",
      },
      {
        type: "testUpload",
        name: "Behavior Rating Inventory of Executive Function, Second Edition (BRIEF-2)",
        sourceId: "ef50b06f-bf79-4ac1-864d-3b06e7a9a219",
      },
      {
        type: "testingPlan",
        name: "Brown Executive Function/Attention Scales (Brown EF/A)",
        testingPlanMatches: ["adhd-exec-func-measure~brown-self"],
      },
      {
        type: "testingPlan",
        name: "Social Skills Improvement System (SSIS) Self Report ",
        testingPlanMatches: ["social-emotional-measure~ssis"],
      },
    ],
  },
  {
    title: "Rating Scale Measures",
    sources: [
      {
        type: "testingPlan",
        name: "Autism Spectrum Rating Scales (ASRS)",
        testingPlanMatches: ["autism-measure~asrs-pt", "autism-measure~asrs-self"],
      },
      {
        type: "testingPlan",
        name: "Beck Depression Inventory (BDI) ",
        testingPlanMatches: ["social-emotional-measure~bdi-2"],
      },
      {
        type: "testUpload",
        name: "Behavior Assessment System for Children, Third Edition (BASC-3) Parent Rating Scale (PRS)",
        sourceId: "71cb493a-176e-4bb8-b70f-c6b2d8372063",
      },
      {
        type: "testUpload",
        name: "Behavior Assessment System for Children, Third Edition (BASC-3) Teacher Rating Scale (TRS)",
        sourceId: "9e3ee2bc-5688-4c99-9179-81ef5948efee",
      },
      {
        type: "testingPlan",
        name: "Brown Executive Function/Attention Scales (Brown EF/A), Parent Report",
        testingPlanMatches: ["adhd-exec-func-measure~brown-pt"],
      },
      {
        type: "testingPlan",
        name: "Brown Executive Function/Attention Scales (Brown EF/A), Teacher Report",
        testingPlanMatches: ["adhd-exec-func-measure~brown-pt"],
      },
      {
        type: "testUpload",
        name: "Behavior Rating Inventory of Executive Function, Second Edition (BRIEF-2) Parent Report (-PR)",
        sourceId: "492f4fe7-4f64-4e8b-afc4-fd344732610c",
      },
      {
        type: "testUpload",
        name: "Behavior Rating Inventory of Executive Function, Second Edition (BRIEF-2) Teacher Report (-TR)",
        sourceId: "fb201e8a-ff1b-461c-be49-d72d28a7eb88",
      },
      {
        type: "testingPlan",
        name: "Conners Fourth Edition (Conners-4) Parent Rating Scale",
        testingPlanMatches: ["adhd-exec-func-measure~conners-4-pt"],
      },
      {
        type: "testingPlan",
        name: "Conners Fourth Edition (Conners-4) Teacher Rating Scale",
        testingPlanMatches: ["adhd-exec-func-measure~conners-4-pt"],
      },
      {
        type: "testingPlan",
        name: "Developmental Profile (DP-4) Parent Report",
        testingPlanMatches: ["broad-band-measure~dp-4"],
      },
      {
        type: "testingPlan",
        name: "Developmental Profile (DP-4) Teacher Report",
        testingPlanMatches: ["broad-band-measure~dp-4"],
      },
      {
        type: "testUpload",
        name: "Social Responsiveness Scale, Second Edition (SRS-2) Parent Report",
        sourceId: "c70ff5d0-6f0e-4375-8f5b-c814c27d2347",
      },
      {
        type: "testUpload",
        name: "Social Responsiveness Scale, Second Edition (SRS-2) Teacher Report",
        sourceId: "7e4a6192-b8ca-4682-8940-c895368eda18",
      },
      {
        type: "testingPlan",
        name: "Social Skills Improvement System (SSIS)  Parent Report ",
        testingPlanMatches: ["social-emotional-measure~ssis"],
      },
      {
        type: "testingPlan",
        name: "Social Skills Improvement System (SSIS)  Teacher Report ",
        testingPlanMatches: ["social-emotional-measure~ssis"],
      },
      {
        type: "testUpload",
        name: "Vineland Adaptive Behavior Scales, Third Edition (Vineland-3) Parent Report (-PR)",
        sourceId: "bef1f625-309f-4263-bf07-3ea06a75bc6e",
      },
      {
        type: "testUpload",
        name: "Vineland Adaptive Behavior Scales, Third Edition (Vineland-3) Teacher Report (-TR)",
        sourceId: "cd01daaa-334a-4846-9f4c-8bc7ec2754bd",
      },
    ],
  },
  {
    title: "Performance-Based Cognitive and Academic Measures",
    sources: [
      {
        type: "testingPlan",
        name: "Bateria IV Pruebas de Aprovechamiento ",
        testingPlanMatches: ["spanish-academic-toggle~TRUE"],
      },
      {
        type: "testingPlan",
        name: "Bateria IV Pruebas de Cognitivo ",
        testingPlanMatches: ["spanish-cog-toggle~TRUE"],
      },
      {
        type: "testUpload",
        name: "Wechsler Individual Achievement Test, Fourth Edition (WIAT-IV)",
        sourceId: "de25dd6d-f087-449f-85d1-5e9e3fcc45f2",
      },
      {
        type: "testUpload",
        name: "Wechsler Intelligence Scale for Children, Fifth Edition (WISC-V)",
        sourceId: "3dc2c816-fd35-402d-8063-d3accb4fb660",
      },
      {
        type: "testingPlan",
        name: "Wechsler Adult Intelligence Scale, Fifth Edition (WAIS-V)",
        testingPlanMatches: ["wais-iv-toggle~TRUE"],
      },
      {
        type: "testingPlan",
        name: "Woodcock-Johnson IV Tests of Achievement (WJ IV Ach), Form A",
        testingPlanMatches: [
          "wj-iv-reading-standard-toggle~TRUE",
          "wj-iv-reading-extended-toggle~TRUE",
          "wj-iv-math-standard-toggle~TRUE",
          "wj-iv-math-extended-toggle~TRUE",
          "wj-iv-writing-standard-toggle~TRUE",
          "wj-iv-writing-extended-toggle~TRUE",
        ],
      },
      {
        type: "testingPlan",
        name: "Woodcock-Johnson IV Tests of Cognitive Abilities (WJ IV Cog)",
        testingPlanMatches: ["wj-iv-cog-standard-toggle~TRUE", "wj-iv-cog-extended-toggle~TRUE"],
      },
      {
        type: "testingPlan",
        name: "Woodcock-Johnson IV Tests of Oral Language (WJ IV Oral)",
        testingPlanMatches: ["wj-iv-oral-toggle~TRUE"],
      },
    ],
  },
];

export const doesTestingPlanMatch = (answers: FormAnswers, matches: string[]) =>
  matches.some(match => {
    const [matchKey, matchValue] = match.split("~");
    const testingPlanAnswer = get(answers, matchKey);
    return isArray(testingPlanAnswer) ? testingPlanAnswer.includes(matchValue) : testingPlanAnswer === matchValue;
  });

export const getPsychInfoSourceTableData = (report: SingleReport) =>
  mapExists(PSYCH_INFO_SOURCE_TABLE_GROUPS, ({ title, sources }) => {
    const sourceNames = mapExists(sources, source => {
      switch (source.type) {
        case "interview":
          return report.formSubmissions[`${source.formType}Interview`] && source.getName(report);
        case "testUpload":
          return report.testUploads.some(upload => upload.reportTestUploadSourceId === source.sourceId) && source.name;
        case "testingPlan":
          return (
            doesTestingPlanMatch(report.formSubmissions.testingPlanAnswers || {}, source.testingPlanMatches) &&
            source.name
          );
      }
    });
    return !isEmpty(sourceNames) ? { title, sourceNames } : null;
  });
