import { InterviewFormType } from "@parallel/vertex/enums/report.enums";
import {
  CreateReportBody,
  CreateReportEligibilityBody,
  CreateReportNeedGroupBody,
  CustomBlock,
  ExtendedAssessmentEnrollment,
  ExtendedEligibility,
  ExtendedReport,
  ExtendedReportTestUpload,
  Need,
  Recommendation,
  ReportNeedGroup,
  ReportSectionCustom,
  ReportTestUploadIncludeReason,
  ReportTestUploadSkipReason,
  ReportTestUploadSource,
  SearchAssessmentEnrollmentQuery,
  SearchRecommendationsQuery,
  SearchReportQuery,
  SingleReport,
  UpdateReportBlockBody,
  UpdateReportSectionBody,
  UpdateReportUploadBody,
  UpdateTestingPlanBody,
} from "@parallel/vertex/types/assessment/assessment.report.types";
import { PaginatedResult, ReorderRequestBody } from "@parallel/vertex/types/shared.types";
import { BaseAPI } from "@/api/base.api";
import config from "@/config";

export type ReportFileUploadRequest = {
  sourceId: string;
  file: File;
  includeReasonId?: string;
};

export class ReportAPI extends BaseAPI {
  constructor() {
    super({
      parseTimestamps: true,
      parseQueryNulls: true,
      baseURL: config.reportWriterHttpTarget === "report" ? config.nexusReportServerUrl : config.nexusServerUrl,
    });
  }

  public searchAssessmentEnrollments = (
    params: SearchAssessmentEnrollmentQuery,
  ): Promise<PaginatedResult<ExtendedAssessmentEnrollment>> =>
    this.instance.get("assessment/report/enrollment", { params }).then(r => r.data);

  public getAssessmentEnrollment = (serviceLineClientId: string): Promise<ExtendedAssessmentEnrollment> =>
    this.instance.get(`assessment/report/enrollment/${serviceLineClientId}`).then(r => r.data);

  public getReport = (reportId: string): Promise<SingleReport> =>
    this.instance.get(`assessment/report/${reportId}`).then(r => r.data);

  public createReport = (body: CreateReportBody): Promise<SingleReport> =>
    this.instance.post("assessment/report", body).then(r => r.data);

  public searchReports = (query: SearchReportQuery): Promise<PaginatedResult<ExtendedReport>> =>
    this.instance.get("assessment/report", { params: query }).then(r => r.data);

  public updateTestingPlan = (reportId: string, body: UpdateTestingPlanBody): Promise<SingleReport> =>
    this.instance.put(`assessment/report/${reportId}/testing-plan`, body).then(r => r.data);

  public createInterviewNotesSubmission = ({
    interviewType,
    reportId,
  }: {
    interviewType: InterviewFormType;
    reportId: string;
  }) => this.instance.post(`assessment/report/${reportId}/interview-form/${interviewType}`).then(r => r.data);

  public uploadTestFiles = (
    reportId: string,
    files: ReportFileUploadRequest[],
  ): Promise<ExtendedReportTestUpload[]> => {
    const formData = new FormData();
    files.forEach(({ file, sourceId, includeReasonId }) =>
      formData.append(includeReasonId ? `${sourceId}~${includeReasonId}` : sourceId, file, file.name),
    );
    return this.instance.post(`assessment/report/${reportId}/test`, formData).then(r => r.data.uploads);
  };

  public updateTestUpload = (
    reportId: string,
    testUploadId: string,
    body: UpdateReportUploadBody,
  ): Promise<ExtendedReportTestUpload> =>
    this.instance.put(`assessment/report/${reportId}/test/${testUploadId}`, body).then(r => r.data);

  public getTestUploadFileUrl = (reportId: string, testUploadId: string): Promise<string> =>
    this.instance.get(`assessment/report/${reportId}/test/${testUploadId}/file`).then(r => r.data.url);

  public upsertCustomSection = (
    reportId: string,
    sectionTemplateId: string,
    body: UpdateReportSectionBody,
  ): Promise<ReportSectionCustom> =>
    this.instance.put(`assessment/report/${reportId}/section/${sectionTemplateId}`, body).then(r => r.data);

  public updateSubsectionOrder = (
    reportId: string,
    sectionTemplateId: string,
    body: ReorderRequestBody,
  ): Promise<void> =>
    this.instance.post(`assessment/report/${reportId}/section/${sectionTemplateId}/subsection-order`, body);

  public upsertCustomBlock = (
    reportId: string,
    blockTemplateId: string,
    body: UpdateReportBlockBody,
  ): Promise<CustomBlock> =>
    this.instance.put(`assessment/report/${reportId}/block/${blockTemplateId}`, body).then(r => r.data);

  public searchRecommendations = (params: SearchRecommendationsQuery): Promise<PaginatedResult<Recommendation>> =>
    this.instance.get("assessment/report/recommendation", { params }).then(r => r.data);

  public createReportEligibility = (
    reportId: string,
    eligibilityId: string,
    body: CreateReportEligibilityBody,
  ): Promise<SingleReport> =>
    this.instance.post(`assessment/report/${reportId}/eligibility/${eligibilityId}`, body).then(r => r.data);

  public deleteReportEligibility = (reportId: string, eligibilityId: string): Promise<SingleReport> =>
    this.instance.delete(`assessment/report/${reportId}/eligibility/${eligibilityId}`).then(r => r.data);

  public createReportNeedGroups = (reportId: string, groups: CreateReportNeedGroupBody[]): Promise<ReportNeedGroup[]> =>
    this.instance.post(`assessment/report/${reportId}/need/group`, { groups }).then(r => r.data.groups);

  public updateNeedGroupContent = (reportId: string, groupId: string, content: string): Promise<ReportNeedGroup> =>
    this.instance.put(`assessment/report/${reportId}/need/group/${groupId}`, { content }).then(r => r.data);

  public updateNeedGroupOrder = (reportId: string, body: ReorderRequestBody): Promise<void> =>
    this.instance.post(`assessment/report/${reportId}/need/group/order`, body);

  public deleteNeedGroup = (reportId: string, groupId: string): Promise<void> =>
    this.instance.delete(`assessment/report/${reportId}/need/group/${groupId}`);

  public uploadSignature = (reportId: string, file: File): Promise<void> => {
    const formData = new FormData();
    formData.append("signature", file);
    return this.instance.post(`assessment/report/${reportId}/signature`, formData);
  };

  public buildReport = (reportId: string): Promise<{ url: string }> =>
    this.instance.post(`assessment/report/${reportId}/build`).then(r => r.data);

  public getAllTestUploadSources = (): Promise<ReportTestUploadSource[]> =>
    this.instance.get("assessment/report/test/source").then(r => r.data.sources);

  public getAllTestUploadIncludeReasons = (): Promise<ReportTestUploadIncludeReason[]> =>
    this.instance.get("assessment/report/test/include-reason").then(r => r.data.reasons);

  public getAllTestUploadSkipReasons = (): Promise<ReportTestUploadSkipReason[]> =>
    this.instance.get("assessment/report/test/skip-reason").then(r => r.data.reasons);

  public getAllEligibilities = (): Promise<ExtendedEligibility[]> =>
    this.instance.get("assessment/report/eligibility").then(r => r.data.eligibilities);

  public getAllNeeds = (): Promise<Need[]> => this.instance.get("assessment/report/need").then(r => r.data.needs);
}
