import { useContext, useEffect, useState } from "react";
import { Form, updateUserId } from "@feathery/react";
import { ContextOnSubmit } from "@feathery/react/dist/types/Form";
import { Typography } from "@mui/material";
import config from "@/config";
import { StoreContext } from "@/stores";

const SignatureInput = () => {
  const {
    apiStore: { reportApi },
    reportStore: { currentReport },
  } = useContext(StoreContext);

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const reportId = currentReport?.reportId;
  useEffect(() => {
    setIsLoading(true);
    updateUserId(reportId).then(() => setIsLoading(false));
  }, [reportId]);

  if (!currentReport || isLoading) return <></>;

  const onSubmit = async (event: ContextOnSubmit) => {
    const signatureField = event.getFieldValues().signature;
    if (!(signatureField instanceof Promise)) return;

    const file = await signatureField;
    await reportApi.uploadSignature(currentReport.reportId, file);

    setIsSubmitted(true);
  };

  return isSubmitted ? (
    <Typography variant="body1">Signature Submitted Successfully</Typography>
  ) : (
    <Form formId={config.signatureFeatheryFormId} className="form-wrapper" hideTestUI={true} onSubmit={onSubmit} />
  );
};

export default SignatureInput;
