import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import { CircularProgress } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { CenterBox } from "@parallel/polygon/components/shared/layout/container";
import { mapExists } from "@parallel/vertex/util/collection.util";
import SelectInput from "@/components/shared/input/SelectInput";
import PromptLayout from "@/components/shared/layout/PromptLayout";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

type EligibilityParams = {
  eligibilityId: string;
  tokens: {
    tokenId: string;
    label: string;
    options: {
      value: string;
      isSelected: boolean;
    }[];
  }[];
};

const logger = initLogger("AddEligibilityInput", getLoggerContext);

const AddEligibilityInput = ({ onFinished }: { onFinished: () => void }) => {
  const {
    reportStore: { currentReport, eligibilities, loadEligibilities, createReportEligibility },
  } = useContext(StoreContext);

  useEffect(() => {
    !eligibilities && loadEligibilities();
  }, [eligibilities]);

  const [params, setParams] = useState<EligibilityParams>();

  if (!eligibilities)
    return (
      <CenterBox width={500} height={500}>
        <CircularProgress />
      </CenterBox>
    );

  const eligibilityOptions = mapExists(
    eligibilities,
    e => !currentReport?.eligibilityIds.includes(e.eligibilityId) && { key: e.eligibilityId, label: e.name },
  );

  const onEligibilitySelect = (eligibilityId: string) => {
    const selected = eligibilities.find(e => e.eligibilityId === eligibilityId);
    if (!selected) {
      setParams(undefined);
      return;
    }
    setParams({
      eligibilityId,
      tokens: selected.tokens.map(token => ({
        tokenId: token.eligibilityTokenId,
        label: token.label,
        options: token.optionValues.map(value => ({ value, isSelected: false })),
      })),
    });
  };

  const onTokenOptionToggle = (isSelected: boolean, tokenId: string, optionIndex: number) => {
    if (!params) return;
    const updatedTokens = params.tokens.map(token =>
      token.tokenId === tokenId
        ? { ...token, options: token.options.map((o, i) => (i === optionIndex ? { ...o, isSelected } : o)) }
        : token,
    );
    setParams({
      ...params,
      tokens: updatedTokens,
    });
  };

  const addEligibility = async () => {
    if (!params) return;
    const tokens = params.tokens.map(({ tokenId, options }) => ({
      tokenId,
      selectedValues: mapExists(options, o => o.isSelected && o.value),
    }));
    await createReportEligibility(params.eligibilityId, { tokens }).catch(
      logger.handleFailureAndThrow("createReportEligibility"),
    );
    toast.success("Successfully Added Eligibility to Report");
    onFinished();
  };

  return (
    <PromptLayout
      headerText="Add Eligibility"
      leftAction={{ fn: onFinished, label: "Cancel" }}
      rightAction={{ fn: addEligibility, label: "Add", icon: <AddIcon />, disabled: !params }}
    >
      <Stack width={500} gap={3}>
        <Typography variant="body2">Select and eligibility to add to the report</Typography>
        <SelectInput
          label="Eligibility"
          options={eligibilityOptions}
          value={params?.eligibilityId}
          onChange={onEligibilitySelect}
        />
        {params?.tokens.map(token => (
          <Stack gap={2} key={token.tokenId}>
            <Typography variant="subtitle1">{token.label}</Typography>
            {token.options.map((option, i) => (
              <FormControlLabel
                control={
                  <Switch
                    checked={option.isSelected}
                    onChange={e => onTokenOptionToggle(e.target.checked, token.tokenId, i)}
                  />
                }
                label={option.value}
                key={i}
              />
            ))}
          </Stack>
        ))}
      </Stack>
    </PromptLayout>
  );
};

export default AddEligibilityInput;
