import { useContext, useEffect } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DescriptionIcon from "@mui/icons-material/Description";
import Box from "@mui/material/Box";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import { ProcessButton } from "@parallel/polygon/components/shared/input/status.input";
import { FullBox, FullCenterBox, FullStack } from "@parallel/polygon/components/shared/layout/container";
import { getCommaList } from "@parallel/vertex/util/string.util";
import ReportContent from "@/components/report/ReportContent";
import ReportSidebar from "@/components/report/ReportSidebar";
import DeleteDialog from "@/components/shared/feedback/DeleteDialog";
import PrimaryLayout from "@/components/shared/layout/PrimaryLayout";
import SubHeader from "@/components/shared/layout/SubHeader";
import LoadingScreen from "@/screens/LoadingScreen";
import NotFoundScreen from "@/screens/NotFoundScreen";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";
import { getShortServiceLineTitle } from "@/util/service.util";

const logger = initLogger("AssessmentReportScreen", getLoggerContext);

const AssessmentReportScreen = () => {
  const {
    apiStore: { reportApi },
    reportStore: {
      currentReport: report,
      fetchStatus,
      loadReport,
      setPendingDeleteReportEligibilityId,
      pendingDeleteEligibility,
      deletePendingReportEligibility,
      setPendingDeleteNeedGroupId,
      pendingDeleteNeedGroup,
      deletePendingNeedGroup,
    },
  } = useContext(StoreContext);

  const { reportId } = useParams();
  useEffect(() => {
    loadReport(reportId);
  }, [reportId]);

  if (fetchStatus === "not-found") return <NotFoundScreen />;
  if (["loading", "waiting"].includes(fetchStatus) || !report) return <LoadingScreen />;

  const buildReport = async () => {
    const { url } = await reportApi.buildReport(report.reportId);

    const newWindow = window.open(url, "_blank");
    if (!newWindow) {
      logger.warn("upload file pop-up blocked");
      toast.error("Pop-up blocked. Please allow pop-ups for this site.");
    }
  };

  const shortName = getShortServiceLineTitle(report.serviceLine).replace("Assessment - ", "");
  const header = (
    <SubHeader
      icon={<AssessmentIcon />}
      title={report.client.fullName}
      subtitle={`${shortName} due ${report.assessmentDueDate.toLocaleString({ month: "2-digit", day: "numeric" })}`}
      actions={
        !isEmpty(report.testUploads) && (
          <ProcessButton process={buildReport} startIcon={<DescriptionIcon />}>
            Build
          </ProcessButton>
        )
      }
    />
  );

  const pendingDeleteNeedGroupName =
    pendingDeleteNeedGroup &&
    getCommaList(
      pendingDeleteNeedGroup.needs.map(n => n.name),
      { delimiter: "&" },
    );

  return (
    <PrimaryLayout headerContent={header} containerStyles={{ p: 0 }}>
      <FullStack direction="row">
        <Box width={250} height="100%" p={2} sx={{ borderRight: 1, borderColor: "grey.300", overflowY: "auto" }}>
          <ReportSidebar />
        </Box>
        <FullCenterBox flex="1 1 0%" sx={{ overflowY: "auto" }}>
          <FullBox height="100%" maxWidth={1200} p={2}>
            <ReportContent />
          </FullBox>
        </FullCenterBox>
      </FullStack>
      {pendingDeleteEligibility && (
        <DeleteDialog
          text={`Remove ${pendingDeleteEligibility.name}? This action cannot be undone.`}
          onDelete={deletePendingReportEligibility}
          onClose={() => setPendingDeleteReportEligibilityId(undefined)}
        />
      )}
      {pendingDeleteNeedGroupName && (
        <DeleteDialog
          text={`Remove recommendations for needs ${pendingDeleteNeedGroupName}? This action cannot be undone.`}
          onDelete={deletePendingNeedGroup}
          onClose={() => setPendingDeleteNeedGroupId(undefined)}
        />
      )}
    </PrimaryLayout>
  );
};

export default observer(AssessmentReportScreen);
