import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import AddchartIcon from "@mui/icons-material/Addchart";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { AccordionDetails, Box, Stack, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { isEmpty, pick } from "lodash";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { FullBox } from "@parallel/polygon/components/shared/layout/container";
import { UserType } from "@parallel/vertex/enums/user.enums";
import {
  ExtendedAssessmentEnrollment,
  ExtendedReport,
  SearchReportQuery,
} from "@parallel/vertex/types/assessment/assessment.report.types";
import { PaginatedResult, PaginateParams } from "@parallel/vertex/types/shared.types";
import { ExtendedUser } from "@parallel/vertex/types/user/user.types";
import CreateReportForm from "@/components/report/CreateReportForm";
import ReportListFilter from "@/components/report/ReportListFilter";
import { FilterOption } from "@/components/shared/input/FilterSearchInput";
import CenterModal from "@/components/shared/layout/CenterModal";
import DataTable, { DataTableColumn } from "@/components/shared/layout/DataTable";
import PrimaryLayout from "@/components/shared/layout/PrimaryLayout";
import SubHeader from "@/components/shared/layout/SubHeader";
import LoadingScreen from "@/screens/LoadingScreen";
import NotFoundScreen from "@/screens/NotFoundScreen";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

type ReportStudentRow = ExtendedUser & {
  serviceLineClientId: string;
  serviceType: string;
  consentSignedAt?: DateTime;
  assessmentDueDate?: DateTime;
};

type ExtendedReportWithColumnData = ExtendedAssessmentEnrollment & {
  studentName: string;
  reportType: string;
  reviewerName: string;
  providerName?: string;
};

const ACCORDION_STYLES = {
  bgcolor: "highlight.light",
  "& .Mui-focusVisible": { bgcolor: "highlight.light" },
};

const ACCORDION_DEFAULT_CURSOR_STYLES = {
  "& .MuiAccordionSummary-root:hover, .MuiButtonBase-root:hover, .MuiAccordionSummary-root": { cursor: "default" },
};

const ASSIGNED_REPORT_COLUMNS: DataTableColumn<ReportStudentRow>[] = [
  {
    key: "serviceLineClientId",
    header: "Service Enrollment ID",
    hidden: true,
    isID: true,
  },
  { key: "userId", header: "ID", hidden: true },
  {
    key: "fullName",
    header: "Name",
  },
  {
    key: "serviceType",
    header: "Service Type",
    flexWidth: 2,
  },
  {
    key: "consentSignedAt",
    header: "Consent Signed At",
    type: "date",
  },
  {
    key: "assessmentDueDate",
    header: "Assessment Due Date",
    type: "date",
  },
];

const getActiveAndCompleteReportColumns = (userType: UserType): DataTableColumn<ExtendedReportWithColumnData>[] => [
  { key: "reportId", header: "ID", hidden: true, isID: true },
  {
    key: "studentName",
    header: "Name",
  },
  {
    key: "reportType",
    header: "Report Type",
  },
  {
    key: "reviewerName",
    header: "Reviewer",
  },
  {
    key: "assessmentDueDate",
    header: "Assessment Due Date",
    type: "date",
  },
  {
    key: "providerName",
    header: "Provider Name",
    hidden: userType !== "ADMIN",
  },
];

const formatAssessment = (assessment: ExtendedReport): ExtendedReportWithColumnData => ({
  studentName: assessment.client.fullName,
  reportType: assessment.serviceLine.title,
  reviewerName: assessment.reviewer.fullName,
  providerName: assessment.provider.fullName,
  ...assessment,
});

const parseReportStudentRow = (enrollment: ExtendedAssessmentEnrollment) => ({
  ...enrollment.client,
  serviceType: enrollment.serviceLine.title,
  ...pick(enrollment, "serviceLineClientId", "consentSignedAt", "assessmentDueDate"),
});

const logger = initLogger("ReportHomeScreen", getLoggerContext);

const useAssessmentFilterAndSort = ({
  fetchReports,
  filters,
  isCompleted,
  sortModel,
  setAssessmentState,
}: {
  fetchReports: (
    query: SearchReportQuery,
    setStateAction: Dispatch<SetStateAction<PaginatedResult<ExtendedReportWithColumnData> | undefined>>,
  ) => void;
  filters: FilterOption[];
  isCompleted: boolean;
  sortModel: any;
  setAssessmentState: Dispatch<SetStateAction<PaginatedResult<ExtendedReportWithColumnData> | undefined>>;
}) => {
  return useEffect(() => {
    const searchQuery = filters.reduce(
      (currParams, nextFilter) => ({
        ...currParams,
        [nextFilter.searchParamKey]: nextFilter.searchParamValue,
      }),
      {},
    );

    fetchReports({ isCompleted, ...searchQuery, ...sortModel, pageSize: 25, offset: 0 }, setAssessmentState);
  }, [filters, sortModel]);
};

const AssessmentAccordion = ({
  assessmentFilters,
  setAssessmentFilters,
  columns,
  data,
  setSortModel,
  title,
  fetchReports,
  userType,
  canStartExpanded = false,
}: {
  assessmentFilters: FilterOption[];
  setAssessmentFilters: Dispatch<SetStateAction<FilterOption[]>>;
  columns: DataTableColumn<ExtendedReportWithColumnData>[];
  data: PaginatedResult<ExtendedReportWithColumnData> | undefined;
  setSortModel: Dispatch<SetStateAction<any>>;
  title: "Active Reports" | "Completed Reports";
  fetchReports: ({ pageSize, offset }: { pageSize: number; offset: number }) => void;
  userType: UserType;
  canStartExpanded?: boolean;
}) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!canStartExpanded) return;
    if (!isEmpty(data)) setExpanded(true);
  }, [data]);

  const navigate = useNavigate();

  return (
    <Accordion expanded={expanded} sx={{ ...ACCORDION_STYLES, ...ACCORDION_DEFAULT_CURSOR_STYLES }}>
      <AccordionSummary>
        <Stack direction="row" spacing={1} sx={{ justifyContent: "space-between", width: "100%" }}>
          <Box sx={{ alignContent: "center", width: "100%", cursor: "pointer" }} onClick={() => setExpanded(!expanded)}>
            <Typography variant="h3">{title}</Typography>
          </Box>
          <Box>
            {expanded && (
              <ReportListFilter filters={assessmentFilters} setFilters={setAssessmentFilters} userType={userType} />
            )}
          </Box>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <DataTable
          columns={columns}
          data={data?.records}
          onSortModelChange={sortModel => setSortModel(sortModel && sortModel.length > 0 ? sortModel[0] : {})}
          pagination={{
            initialPageSize: 25,
            onPageChange: ({ pageSize, offset }) => fetchReports({ pageSize, offset }),
            totalCount: data?.totalCount || 0,
          }}
          onRowClick={reportId => navigate(`/assessment/report/${reportId}`)}
        />
      </AccordionDetails>
    </Accordion>
  );
};

const ReportHomeScreen = () => {
  const {
    apiStore: { reportApi },
    authStore: { currentUser },
    reportStore: { fetchStatus, createReportEnrollment, loadCreateReportEnrollment, setCreateReportEnrollment },
  } = useContext(StoreContext);

  const { serviceLineClientId } = useParams();
  useEffect(() => {
    loadCreateReportEnrollment(serviceLineClientId);
  }, [serviceLineClientId]);

  const header = <SubHeader icon={<AssessmentIcon />} title="Assessments" />;

  const [activeAssessments, setActiveAssessments] = useState<PaginatedResult<ExtendedReportWithColumnData>>();
  const [completedAssessments, setCompletedAssessments] = useState<PaginatedResult<ExtendedReportWithColumnData>>();
  const [eligibleEnrollmentsPage, setEligibleEnrollmentsPage] =
    useState<PaginatedResult<ExtendedAssessmentEnrollment>>();
  const [activeAndCompleteReportColumns, setActiveAndCompleteReportColumns] = useState<
    DataTableColumn<ExtendedReportWithColumnData>[]
  >([]);

  const [activeAssessmentFilters, setActiveAssessmentFilters] = useState<FilterOption[]>([]);
  const [completedAssessmentFilters, setCompletedAssessmentFilters] = useState<FilterOption[]>([]);
  const [activeAssessmentSortModel, setActiveAssessmentSortModel] = useState<any>();
  const [completedAssessmentSortModel, setCompletedAssessmentSortModel] = useState<any>();

  const fetchReadyStudents = (page: PaginateParams = { pageSize: 25 }) =>
    reportApi
      .searchAssessmentEnrollments({ ...page, reportId: null })
      .then(setEligibleEnrollmentsPage)
      .catch(logger.handleFailure("searchAssessmentEnrollments"));

  const fetchReports = (
    query: SearchReportQuery,
    setStateAction: Dispatch<SetStateAction<PaginatedResult<ExtendedReportWithColumnData> | undefined>>,
  ) =>
    reportApi.searchReports(query).then(data =>
      setStateAction({
        ...data,
        records: data.records.map(formatAssessment),
      }),
    );

  useEffect(() => {
    fetchReadyStudents();
  }, []);

  useAssessmentFilterAndSort({
    fetchReports,
    filters: activeAssessmentFilters,
    isCompleted: false,
    sortModel: activeAssessmentSortModel,
    setAssessmentState: setActiveAssessments,
  });

  useAssessmentFilterAndSort({
    fetchReports,
    filters: completedAssessmentFilters,
    isCompleted: true,
    sortModel: completedAssessmentSortModel,
    setAssessmentState: setCompletedAssessments,
  });

  useEffect(() => {
    if (currentUser) {
      setActiveAndCompleteReportColumns(getActiveAndCompleteReportColumns(currentUser.userType));
    }
  }, [currentUser]);

  const navigate = useNavigate();

  const [isAssignedExpanded, setIsAssignedExpanded] = useState(false);
  useEffect(() => {
    if (!isEmpty(eligibleEnrollmentsPage?.records)) setIsAssignedExpanded(true);
  }, [eligibleEnrollmentsPage]);

  if (["loading", "waiting"].includes(fetchStatus)) return <LoadingScreen />;
  if (fetchStatus === "not-found") return <NotFoundScreen />;

  return (
    <PrimaryLayout headerContent={header}>
      <FullBox sx={{ py: 2 }}>
        <Accordion expanded={isAssignedExpanded} sx={ACCORDION_STYLES}>
          <AccordionSummary onClick={() => setIsAssignedExpanded(!isAssignedExpanded)}>
            <Typography variant="h3">Assigned Reports</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DataTable
              columns={ASSIGNED_REPORT_COLUMNS}
              data={eligibleEnrollmentsPage?.records.map(parseReportStudentRow)}
              pagination={{
                initialPageSize: 25,
                onPageChange: fetchReadyStudents,
                totalCount: eligibleEnrollmentsPage?.totalCount || 0,
              }}
              actions={[
                {
                  size: "full",
                  icon: <AddchartIcon />,
                  label: "Create Assessment",
                  onClick: id => {
                    setCreateReportEnrollment(eligibleEnrollmentsPage?.records.find(s => s.serviceLineClientId === id));
                    navigate(`/assessment/start/${id}`);
                  },
                  width: 200,
                },
              ]}
            />
          </AccordionDetails>
        </Accordion>

        <AssessmentAccordion
          title={"Active Reports"}
          assessmentFilters={activeAssessmentFilters}
          columns={activeAndCompleteReportColumns}
          data={activeAssessments}
          setSortModel={setActiveAssessmentSortModel}
          setAssessmentFilters={setActiveAssessmentFilters}
          fetchReports={({ pageSize, offset }: { pageSize: number; offset: number }) =>
            fetchReports(
              { isCompleted: false, ...activeAssessmentFilters, ...activeAssessmentSortModel, pageSize, offset },
              setActiveAssessments,
            )
          }
          userType={currentUser?.userType || "PROVIDER"}
          canStartExpanded={!isEmpty(activeAssessments)}
        />

        <AssessmentAccordion
          title={"Completed Reports"}
          assessmentFilters={completedAssessmentFilters}
          columns={activeAndCompleteReportColumns}
          data={completedAssessments}
          setSortModel={setCompletedAssessmentSortModel}
          setAssessmentFilters={setCompletedAssessmentFilters}
          fetchReports={() =>
            fetchReports(
              { isCompleted: true, ...completedAssessmentFilters, ...completedAssessmentSortModel },
              setCompletedAssessments,
            )
          }
          userType={currentUser?.userType || "PROVIDER"}
        />
      </FullBox>

      <CenterModal isOpen={!!createReportEnrollment} onClose={() => navigate("/assessment")} minWidth={400}>
        {createReportEnrollment && (
          <CreateReportForm
            enrollment={createReportEnrollment}
            onCreated={reportId => navigate(`/assessment/report/${reportId}`)}
            onClose={() => navigate("/assessment")}
          />
        )}
      </CenterModal>
    </PrimaryLayout>
  );
};

export default observer(ReportHomeScreen);
