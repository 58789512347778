import Box from "@mui/material/Box";
import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import Document from "@tiptap/extension-document";
import Italic from "@tiptap/extension-italic";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import Underline from "@tiptap/extension-underline";
import { Editor, EditorProvider } from "@tiptap/react";
import { noop } from "lodash";
import { Status } from "@parallel/polygon/components/shared/input/status.input";
import BlockContentMenu from "@/components/report/editor/content/BlockContentMenu";
import { getContentHtmlString, InjectedValueMark } from "@/util/tiptap.util";

const defaultExtensions = [
  Document,
  Paragraph,
  Text,
  InjectedValueMark,
  Bold,
  Italic,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
];

const TiptapInput = ({
  content,
  placeholder,
  saveContent,
  saveStatus,
  areCommentsExpanded,
  setAreCommentsExpanded = noop,
}: {
  content?: string;
  placeholder?: string;
  saveContent: (content: string) => unknown;
  saveStatus?: Status;
  areCommentsExpanded?: boolean;
  setAreCommentsExpanded?: (areExpanded: boolean) => void;
}) => {
  const onUpdate = ({ editor }: { editor: Editor }) => {
    const content = getContentHtmlString(editor);
    if (!content) return;
    saveContent(content);
  };

  const extensions = placeholder ? [...defaultExtensions, Placeholder.configure({ placeholder })] : defaultExtensions;

  return (
    <Box
      width="100%"
      sx={{
        border: 1,
        borderColor: "grey.300",
        borderRadius: 1,
        "&:focus-within": { borderColor: "primary.main" },
        "& .tiptap": {
          minHeight: 200,
          p: 1,
          outline: 0,
        },
      }}
    >
      <EditorProvider
        extensions={extensions}
        content={content}
        slotBefore={
          <BlockContentMenu
            saveStatus={saveStatus}
            areCommentsExpanded={areCommentsExpanded}
            setAreCommentsExpanded={setAreCommentsExpanded}
          />
        }
        onUpdate={onUpdate}
      />
    </Box>
  );
};

export default TiptapInput;
