import { Dispatch, SetStateAction } from "react";
import { Stack } from "@mui/material";
import { UserType } from "@parallel/vertex/enums/user.enums";
import FilterSearchInput, { FilterOption } from "@/components/shared/input/FilterSearchInput";

const ReportListFilter = ({
  filters,
  setFilters,
  userType,
}: {
  filters: FilterOption[];
  setFilters: Dispatch<SetStateAction<FilterOption[]>>;
  userType?: UserType;
}) => {
  const filterOptions = [
    { propertyName: "Student Name", searchParamKey: "studentName" },
    { propertyName: "Reviewer Name", searchParamKey: "reviewerName" },
    { propertyName: "Service Line", searchParamKey: "serviceLineTitle" },
  ];

  if (userType === "ADMIN") {
    filterOptions.push({ propertyName: "Provider Name", searchParamKey: "providerName" });
  }
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" gap={2.5}>
        <FilterSearchInput
          properties={filterOptions}
          selectedOptions={filters}
          setSelectedOptions={setFilters}
          width={540}
        />
      </Stack>
    </Stack>
  );
};

export default ReportListFilter;
