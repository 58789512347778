import { useContext } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useDebouncedProcessStatus } from "@parallel/polygon/components/shared/input/status.input";
import { SplitRow } from "@parallel/polygon/components/shared/layout/container";
import { ReportNeedGroup } from "@parallel/vertex/types/assessment/assessment.report.types";
import { getNeedGroupContent } from "@parallel/vertex/util/assessment.report.util";
import TiptapInput from "@/components/report/editor/content/TiptapInput";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";
import { useEditorAutoScroll } from "@/util/report.util";

const logger = initLogger("RecommendationEditorSection", getLoggerContext);

const RecommendationEditorSection = ({ needGroup }: { needGroup: ReportNeedGroup }) => {
  const {
    reportStore: { updateNeedGroupContent, setPendingDeleteNeedGroupId },
  } = useContext(StoreContext);

  const { containerRef } = useEditorAutoScroll(needGroup.groupId);

  const { content, header } = getNeedGroupContent(needGroup);

  const { status, perform } = useDebouncedProcessStatus(
    (content: string) =>
      updateNeedGroupContent(needGroup.groupId, content).catch(logger.handleFailureAndThrow("updateNeedGroupContent")),
    { saveKey: needGroup.groupId },
  );

  return (
    <Stack width="100%" gap={1} ref={containerRef}>
      <SplitRow
        left={<Typography variant="h3">{header}</Typography>}
        right={
          <IconButton onClick={() => setPendingDeleteNeedGroupId(needGroup.groupId)} size="small">
            <DeleteIcon fontSize="small" />
          </IconButton>
        }
      />

      <TiptapInput content={content} saveContent={perform} saveStatus={status} />
    </Stack>
  );
};

export default RecommendationEditorSection;
