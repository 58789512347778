import { ReactNode, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";
import CalendarItemSubHeader from "@/components/calendar/CalendarItemSubHeader";
import AppointmentEventsList from "@/components/calendar/appointment/events/AppointmentEventsList";
import AppointmentEventsSummary from "@/components/calendar/appointment/events/AppointmentEventsSummary";
import PrimaryLayout from "@/components/shared/layout/PrimaryLayout";
import LoadingScreen from "@/screens/LoadingScreen";
import NotFoundScreen from "@/screens/NotFoundScreen";
import { StoreContext } from "@/stores";

const APPOINTMENT_LOGS_TABS = ["summary", "list"] as const;

type AppointmentEventsTab = (typeof APPOINTMENT_LOGS_TABS)[number];

const APPOINTMENT_LOGS_TAB_LABELS: Record<AppointmentEventsTab, string> = {
  summary: "Summary",
  list: "All Logs",
};

const AppointmentEventsScreen = () => {
  const {
    authStore: { currentUser },
    calendarStore,
  } = useContext(StoreContext);
  const { fetchStatus, selectedAppointment } = calendarStore;

  const { itemId: appointmentId } = useParams();
  useEffect(() => {
    if (!appointmentId) return;
    calendarStore.loadItem({ itemId: appointmentId, itemType: "appointment" });
  }, [appointmentId]);

  const [currentTab, setCurrentTab] = useState<AppointmentEventsTab>("summary");

  if (currentUser?.userType !== "ADMIN") return <NotFoundScreen />;

  if (!appointmentId || !selectedAppointment)
    return fetchStatus === "not-found" ? <NotFoundScreen /> : <LoadingScreen />;

  const header = (
    <CalendarItemSubHeader
      appointment={selectedAppointment}
      navigation={{
        tabs: APPOINTMENT_LOGS_TABS.map(key => ({ key, label: APPOINTMENT_LOGS_TAB_LABELS[key] })),
        currentKey: currentTab,
        onChange: setCurrentTab,
      }}
    />
  );

  let content: ReactNode = <></>;
  if (currentTab === "summary") content = <AppointmentEventsSummary appointment={selectedAppointment} />;
  if (currentTab === "list") content = <AppointmentEventsList appointment={selectedAppointment} />;

  return <PrimaryLayout headerContent={header}>{content}</PrimaryLayout>;
};

export default observer(AppointmentEventsScreen);
