import { DateTime } from "luxon";
import { ZodString, z } from "zod";

export type Override<T1, T2> = Omit<T1, keyof T2> & T2;

const nameSchema = z.string().trim().max(255, { message: "Name must be 255 characters or less" });

export const requiredNameSchema = nameSchema.min(1, { message: "Name must be at least 1 character long" });

export const optionalNameSchema = nameSchema.transform(s => (s === "" ? undefined : s)).optional();

export const namedEntitySchema = z.object({ id: z.string().uuid(), name: z.string() });

export const emailSchema = z.string().email().trim().toLowerCase();
export const optionalEmailSchema = z.preprocess(s => (s === "" ? undefined : s), emailSchema.optional());

export const nullableStringSchema = z.preprocess(s => (s === "" ? null : s), z.string().nullable().optional());

export const positiveIntegerSchema = z.coerce.number().int().positive();

export const booleanQuerySchema = z.union([z.string().transform(s => s.toLowerCase()[0] === "t"), z.boolean()]);

export const nullableQueryStringSchema = z
  .string()
  .nullable()
  .transform(s => (s?.toLowerCase() === "null" ? null : s));

const paginateFields = {
  offset: z.coerce.number().int().gte(0),
  pageSize: z.coerce.number().int().gte(0),
};

const paginateStateSchema = z.object(paginateFields);

export type PaginateState = z.infer<typeof paginateStateSchema>;

export const paginateQueryFields = {
  offset: paginateFields.offset.optional(),
  pageSize: paginateFields.pageSize.optional(),
};

export const sortQueryFields = {
  sortField: z.string().optional(),
  sortOrder: z.enum(["asc", "desc"]).optional(),
};

const paginateParamsSchema = z.object(paginateQueryFields);

export type PaginateParams = z.infer<typeof paginateParamsSchema>;

export type PaginatedResult<R> = {
  records: R[];
  totalCount: number;
};

export type DateTimeRange = { startTime: DateTime; endTime: DateTime };

export const datetimeQueryField = z.union([
  z
    .string()
    .datetime()
    .transform(s => DateTime.fromISO(s).toUTC()),
  z
    .string()
    .regex(/^\d+$/)
    .transform(s => DateTime.fromMillis(parseInt(s)).toUTC()),
]);

export const dateTimeClass = z
  .any()
  .refine((x): x is DateTime => x instanceof DateTime && x.isValid, "Invalid DateTime class");

export const datetimeRequestField = z.union([
  datetimeQueryField,
  dateTimeClass,
  z
    .number()
    .int()
    .positive()
    .transform(n => DateTime.fromMillis(n)),
]);

export const reorderRequestBodySchema = z.object({
  fromIndex: z.number(),
  toIndex: z.number(),
});
export type ReorderRequestBody = z.infer<typeof reorderRequestBodySchema>;

export type Position = { x: number; y: number };

/**
 * values should all be decimal offsets within [0,1] indicating offset area of contents relative to some arbitrary reference area
 */
export const offsetRelativeAreaSchema = z.object({
  width: z.number(),
  height: z.number().optional(), // if height is undefined, maintain the aspect ratio of the source
  offset: z.object({ x: z.number(), y: z.number() }),
});

export type OffsetRelativeArea = z.infer<typeof offsetRelativeAreaSchema>;

export const queryStringArray = (stringOverride?: ZodString) => {
  const string = stringOverride || z.string();
  return z.union([string.transform(s => [s]), string.array()]);
};

export type FirestoreTimestamp = {
  epochMs: number;
  isoString: string;
};
