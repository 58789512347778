import { useContext } from "react";
import { useDebouncedProcessStatus } from "@parallel/polygon/components/shared/input/status.input";
import { ReportEditorBlock } from "@parallel/vertex/types/assessment/assessment.report.types";
import TiptapInput from "@/components/report/editor/content/TiptapInput";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("BlockContentInput", getLoggerContext);

const BlockContentInput = ({
  block,
  areCommentsExpanded,
  setAreCommentsExpanded,
}: {
  block: ReportEditorBlock;
  areCommentsExpanded: boolean;
  setAreCommentsExpanded: (areExpanded: boolean) => void;
}) => {
  const {
    reportStore: { upsertCustomBlock },
  } = useContext(StoreContext);

  const { reportBlockTemplateId } = block;
  const { status: saveStatus, perform: upsertCustomContent } = useDebouncedProcessStatus(
    (content: string) =>
      upsertCustomBlock(reportBlockTemplateId, { content }).catch(logger.handleFailureAndThrow("upsertCustomContent")),
    { saveKey: reportBlockTemplateId },
  );

  return (
    <TiptapInput
      content={block.custom?.content || block.content || undefined}
      placeholder={block.placeholder || undefined}
      saveContent={upsertCustomContent}
      saveStatus={saveStatus}
      areCommentsExpanded={areCommentsExpanded}
      setAreCommentsExpanded={setAreCommentsExpanded}
    />
  );
};

export default BlockContentInput;
