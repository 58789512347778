import { ReactNode, useContext, useState } from "react";
import { useSearchParams } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoginIcon from "@mui/icons-material/Login";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import grey from "@mui/material/colors/grey";
import { useGoogleLogin } from "@react-oauth/google";
import { StatusButton, useFormStatus } from "@parallel/polygon/components/shared/input/status.input";
import CenterLayout from "@/components/shared/layout/CenterLayout";
import { LinkButton } from "@/components/shared/navigation/link";
import config from "@/config";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const LOGIN_ERROR_CAUSE = "Unexpected error signing in";
const LOGIN_ERROR_ACTIONS = {
  retry: "Please try again and contact support if the issue persists",
  ensure: "Please ensure you are signing in as a valid user and contact support if the issue persists",
} as const;

const buildErrorMessage = (action: keyof typeof LOGIN_ERROR_ACTIONS, cause: string = LOGIN_ERROR_CAUSE) =>
  `${cause} - ${LOGIN_ERROR_ACTIONS[action]}`;

const logger = initLogger("LoginScreen", getLoggerContext);

const LoginScreen = () => {
  const {
    apiStore: { authApi },
    authStore,
  } = useContext(StoreContext);

  const [errorMessage, setErrorMessage] = useState("");

  const performGoogleLogin = useGoogleLogin({
    onSuccess: ({ access_token: accessToken }) => {
      authStore.googleSignIn(accessToken).catch(e => {
        logger.error("error verifying google login response", e);
        setErrorMessage(buildErrorMessage("ensure"));
      });
    },
    onError: () => {
      logger.error("GoogleLogin component error", { sentryIssueLevel: "error" });
      setErrorMessage(buildErrorMessage("retry"));
    },
  });

  const [query] = useSearchParams();
  const emailParam = query.get("email");

  const [email, setEmail] = useState(emailParam || "");
  const [password, setPassword] = useState("");

  const {
    status: submitStatus,
    perform: onPasswordFormSubmit,
    setStatus: setSubmitStatus,
  } = useFormStatus(() => {
    setErrorMessage("");
    return authStore.passwordSignIn({ email, password }).catch(e => {
      logger.logFailure("passwordSignIn", e);
      setErrorMessage(e.message);
      throw e;
    });
  });

  const cleverParams: Record<string, string> = {
    response_type: "code",
    redirect_uri: `${config.pathwayClientUrl}/clever`,
    client_id: config.cleverClientId,
  };
  if (config.cleverSandboxDistrictId) cleverParams.district_id = config.cleverSandboxDistrictId;
  const cleverSignInUrl = `https://clever.com/oauth/authorize?${new URLSearchParams(cleverParams)}`;

  const [isResettingPassword, setIsResettingPassword] = useState(false);

  let content: ReactNode = (
    <>
      <Button
        startIcon={
          <img
            alt="Google Login Button"
            src={`${config.staticAssetsUrl}/logos/google-logo.svg`}
            style={{ height: 20, width: 20, marginRight: 4 }}
          />
        }
        onClick={() => performGoogleLogin()}
        variant="outlined"
        sx={{ textTransform: "none", width: "100%", fontWeight: "bold" }}
      >
        Sign In With Google
      </Button>

      <LinkButton
        newTab
        href={cleverSignInUrl}
        startIcon={
          <img
            alt="Clever Login Button"
            src={`${config.staticAssetsUrl}/logos/clever-logo.svg`}
            style={{ height: 18, width: 18, marginRight: 4 }}
          />
        }
        variant="outlined"
        sx={{ textTransform: "none", width: "100%", fontWeight: "bold" }}
      >
        Sign In With Clever
      </LinkButton>

      <Stack position="relative" width="100%" direction="row" justifyContent="center">
        <Box
          position="absolute"
          width="100%"
          height="50%"
          pb={1}
          sx={{ borderBottomWidth: 1, borderColor: grey[400] }}
        />
        <Box position="relative" px={1} bgcolor={grey[50]}>
          <Typography variant="subtitle2" color={grey[600]}>
            Or sign in with email
          </Typography>
        </Box>
      </Stack>

      <form onSubmit={onPasswordFormSubmit}>
        <Stack gap={2}>
          <TextField
            name="email"
            label="Email"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            fullWidth
          />
          <TextField
            name="password"
            label="Password"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            fullWidth
          />
          <StatusButton
            variant="contained"
            startIcon={<LoginIcon />}
            type="submit"
            status={submitStatus}
            style={{ fontWeight: "bold" }}
          >
            Sign In
          </StatusButton>
          <div>
            <Button
              onClick={() => {
                setPassword("");
                setErrorMessage("");
                setSubmitStatus(undefined);
                setIsResettingPassword(true);
              }}
            >
              Reset Password
            </Button>
          </div>
        </Stack>
      </form>
      {errorMessage && <Typography color="error.main">{errorMessage}</Typography>}
    </>
  );

  const {
    status: resetPasswordStatus,
    perform: startPasswordReset,
    setStatus: setResetPasswordStatus,
  } = useFormStatus(() =>
    authApi.startPasswordReset(email).catch(e => {
      logger.logFailure("startPasswordReset", e);
      throw e;
    }),
  );

  if (isResettingPassword) {
    const backButton = (
      <div>
        <Button
          onClick={() => {
            setResetPasswordStatus(undefined);
            setIsResettingPassword(false);
          }}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
      </div>
    );
    if (resetPasswordStatus?.value === "success") {
      content = (
        <>
          <Typography>
            If an account is registered to {email} you will receive an email with a link to reset your password.
          </Typography>
          <Typography>
            If you don't receive an email, check your spam / junk folder and confirm that you're using the same email
            address that was used to create your account.
          </Typography>
          {backButton}
        </>
      );
    } else {
      content = (
        <>
          <Typography>Submit your email to reset your password.</Typography>
          <form onSubmit={startPasswordReset}>
            <Stack gap={2}>
              <TextField label="Email" type="email" value={email} onChange={e => setEmail(e.target.value)} fullWidth />
              <StatusButton variant="contained" type="submit" status={resetPasswordStatus} disabled={!email}>
                Submit
              </StatusButton>
            </Stack>
          </form>
          <Stack gap={1}>
            <Typography variant="body2">
              If you are having trouble joining a session and need immediate technical assistance, please call
              <br />
              {config.supportLines.phone} (available {config.supportLines.phoneHours}).
            </Typography>
            <Typography variant="body2">
              For all other assistance, please email
              <br />
              <Link href={`mailto:${config.supportLines.email}`}>{config.supportLines.email}</Link> (available{" "}
              {config.supportLines.phoneHours}).
            </Typography>
          </Stack>
          {backButton}
        </>
      );
    }
  }

  return <CenterLayout>{content}</CenterLayout>;
};

export default LoginScreen;
