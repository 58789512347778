import { ReactNode } from "react";
import Stack from "@mui/material/Stack";

const RightMarginBox = ({ children, maxHeight }: { children: ReactNode; maxHeight?: number }) => (
  <Stack gap={1} sx={{ position: "absolute", top: 0, left: 800, width: 360, pr: 0.5, maxHeight, overflowY: "auto" }}>
    {children}
  </Stack>
);

export default RightMarginBox;
