import { useContext } from "react";
import { useLocation, useNavigate } from "react-router";
import { useMountEffect } from "@react-hookz/web";
import LoadingScreen from "@/screens/LoadingScreen";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("CleverLoginScreen", getLoggerContext);

const CleverLoginScreen = () => {
  const { authStore } = useContext(StoreContext);

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const cleverToken = queryParams.get("code");

  const navigate = useNavigate();

  useMountEffect(() => {
    if (!cleverToken) {
      logger.warn("missing required `code` param - redirecting to home");
      navigate("/");
      return;
    }
    authStore
      .cleverSignIn(cleverToken)
      .catch(e => logger.error("error verifying clever code - redirecting to home", e))
      .finally(() => navigate("/"));
  });

  return <LoadingScreen />;
};

export default CleverLoginScreen;
