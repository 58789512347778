import { ReactNode, useContext, useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { getGeneralInformationData } from "@parallel/vertex/util/assessment.report.util";
import ReportStudentForm from "@/components/report/editor/content/ReportStudentForm";
import RightMarginBox from "@/components/report/editor/margin/RightMarginBox";
import CenterModal from "@/components/shared/layout/CenterModal";
import { StoreContext } from "@/stores";

const InfoRow = ({ label, value }: { label: string; value: ReactNode }) => {
  return (
    <Stack direction="row" height="40px" width="100%" sx={{ borderTop: 1, borderColor: "grey.200" }}>
      <Stack direction="row" height="100%" width="25%" pl={2} alignItems="center" sx={{ bgcolor: "highlight.main" }}>
        <Typography variant="subtitle1">{label}</Typography>
      </Stack>

      <Stack direction="row" height="100%" width="75%" pl={2} alignItems="center">
        {value}
      </Stack>
    </Stack>
  );
};

const GeneralInformationTable = () => {
  const {
    reportStore: { currentReport, testingSessions },
  } = useContext(StoreContext);

  const [isEditing, setIsEditing] = useState(false);

  if (!currentReport) return <></>;

  const { clientData, reportData } = getGeneralInformationData(currentReport, testingSessions);

  return (
    <Stack sx={{ borderBottom: 1, borderColor: "grey.200", position: "relative" }}>
      {clientData.map(([label, value], i) => (
        <InfoRow label={label} value={<Typography variant="body1">{value}</Typography>} key={`client-${i}`} />
      ))}
      <Box height="20px" sx={{ borderTop: 1, borderColor: "grey.200" }} />
      {reportData.map(([label, value], i) => (
        <InfoRow label={label} value={<Typography variant="body1">{value}</Typography>} key={`report-${i}`} />
      ))}

      <RightMarginBox>
        <Box>
          <Button endIcon={<ChevronRightIcon />} onClick={() => setIsEditing(true)}>
            Edit Student Information
          </Button>
        </Box>
      </RightMarginBox>

      <CenterModal isOpen={isEditing} onClose={() => setIsEditing(false)} minWidth={500}>
        {isEditing && <ReportStudentForm report={currentReport} onFinished={() => setIsEditing(false)} />}
      </CenterModal>
    </Stack>
  );
};

export default GeneralInformationTable;
