import { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import LoadingScreen from "@/screens/LoadingScreen";
import { StoreContext } from "@/stores";

const HomeScreen = () => {
  const {
    authStore: { currentUser },
  } = useContext(StoreContext);

  const navigate = useNavigate();
  const userType = currentUser?.userType;
  useEffect(() => {
    navigate(userType === "ADMIN" ? "/user" : "/calendar");
  }, [userType]);

  return <LoadingScreen />;
};

export default HomeScreen;
