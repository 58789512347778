import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ProcessButton } from "@parallel/polygon/components/shared/input/status.input";
import { CenterBox } from "@parallel/polygon/components/shared/layout/container";

const DeleteDialog = ({
  text,
  onDelete,
  onClose,
}: {
  text: string;
  onDelete: () => Promise<unknown>;
  onClose: () => void;
}) => {
  return (
    <CenterBox position="fixed" bottom={0} width="100%" height={76} py={1}>
      <Paper sx={{ px: 2, bgcolor: "error.main", color: "white" }}>
        <Stack direction="row" height={48} gap={6} alignItems="center">
          <Typography variant="body1">{text}</Typography>

          <Stack direction="row" gap={1} alignItems="center">
            <ProcessButton process={onDelete} startIcon={<DeleteIcon />} sx={{ color: "white" }}>
              Delete
            </ProcessButton>
            <IconButton onClick={onClose} sx={{ color: "white" }}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Paper>
    </CenterBox>
  );
};

export default DeleteDialog;
