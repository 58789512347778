import { ReactNode, useContext, useEffect, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EventIcon from "@mui/icons-material/Event";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import SettingsIcon from "@mui/icons-material/Settings";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Grid2 from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { groupBy, isEmpty, uniqBy } from "lodash";
import { DateTime } from "luxon";
import { stepValuesSchema } from "@parallel/polygon/components/progress/metric/AccuracyInput";
import AutoCompleteInput, { SelectOption } from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import { FullGrid, FullStack } from "@parallel/polygon/components/shared/layout/container";
import {
  ObjectiveMetricValue,
  SingleStudentAppointmentProgress,
  StudentGoal,
  StudentObjective,
} from "@parallel/vertex/types/progress.types";
import { DateTimeRange, PaginatedResult, PaginateState } from "@parallel/vertex/types/shared.types";
import { mapExists } from "@parallel/vertex/util/collection.util";
import { toLocalDate } from "@parallel/vertex/util/datetime.util";
import { secondsToString, toPercentString } from "@parallel/vertex/util/number.util";
import { toTitleCase } from "@parallel/vertex/util/string.util";
import { StyledMenu } from "@/components/shared/display/menu";
import ToggleMenuItem from "@/components/shared/input/ToggleMenuItem";
import PaginationControl from "@/components/shared/navigation/PaginationControl";
import LoadingScreen from "@/screens/LoadingScreen";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const NAME_SELECT_GROUPS = {
  goals: "Goals",
  objectives: "Objectives",
} as const;

type NameSelectGroup = (typeof NAME_SELECT_GROUPS)[keyof typeof NAME_SELECT_GROUPS];

const AppointmentEntryRow = ({
  leftContent,
  rightContent,
  borderBottom = 1,
}: {
  leftContent: ReactNode;
  rightContent?: ReactNode;
  borderBottom?: number;
}) => (
  <FullGrid container borderBottom={borderBottom} borderColor="grey.300" px={2} py={2} alignItems="center">
    <Grid2 size={{ xs: 6 }}>{leftContent}</Grid2>
    <Grid2 size={{ xs: 6 }} pl={4}>
      {rightContent}
    </Grid2>
  </FullGrid>
);

const ObjectiveTitleContent = ({
  objective,
  objectiveIndex,
  goal,
  goalIndex,
  showGoals,
}: {
  objective: StudentObjective;
  objectiveIndex: number;
  goal: StudentGoal;
  goalIndex: number;
  showGoals: boolean;
}) => (
  <Stack gap={2}>
    <Stack>
      <Typography variant="h3" mb={0.5}>
        {toTitleCase(objective.category)} Objective {goalIndex}.{objectiveIndex}
      </Typography>
      <Typography variant="body1">{objective.description}</Typography>
    </Stack>
    {showGoals && (
      <Stack direction="row" alignItems="top" gap={1}>
        <KeyboardReturnIcon sx={{ fontSize: "18px", transform: "scaleX(-1)", color: "primary.main" }} />
        <Typography variant="body2">
          <strong>Goal {goalIndex}:</strong> {goal.description}
        </Typography>
      </Stack>
    )}
  </Stack>
);

const MetricEntryContent = ({ objective, metric }: { objective: StudentObjective; metric: ObjectiveMetricValue }) => {
  if (metric.type === "number") {
    switch (objective.category) {
      case "ACCURACY":
        const steps = stepValuesSchema.safeParse(metric.metadata)?.data;
        return (
          metric.type === "number" && (
            <Stack direction="row" gap={4}>
              <Typography variant="body1">{toPercentString(metric.value)}</Typography>
              {steps && (
                <Stack direction="row" gap={2}>
                  (<Typography variant="body1">{steps.correct} Correct</Typography> /
                  <Typography variant="body1">{steps.incorrect} Incorrect</Typography> /
                  <Typography variant="body1">{steps.prompt} Prompt</Typography>)
                </Stack>
              )}
            </Stack>
          )
        );
      case "DURATION":
        return <Typography variant="body1">{secondsToString(metric.value)} Duration</Typography>;
      case "DECREASING_FREQUENCY":
      case "INCREASING_FREQUENCY":
      case "PROMPTING_FREQUENCY":
        return <Typography variant="body1">{metric.value} Occurrences</Typography>;
    }
  } else if (objective.category === "ANECDOTAL") {
    return <Typography variant="body1">{metric.value}</Typography>;
  }

  return (
    <Typography variant="body1" fontStyle="italic">
      Invalid Data
    </Typography>
  );
};

const AppointmentEntry = ({
  progress,
  itemFilter,
  showGoals,
  showCompleted,
  showArchived,
}: {
  progress: SingleStudentAppointmentProgress;
  itemFilter: SelectOption | null;
  showGoals: boolean;
  showCompleted: boolean;
  showArchived: boolean;
}) => {
  const {
    authStore: { timezone },
  } = useContext(StoreContext);

  const shouldOmit = (group: NameSelectGroup, itemKey: string, isItemComplete: boolean, isItemArchived: boolean) =>
    (itemFilter?.groupName === group && itemFilter.key !== itemKey) ||
    (!showCompleted && isItemComplete) ||
    (!showArchived && isItemArchived);

  const objectiveRowProps = progress.goals.flatMap((goal, i) => {
    if (shouldOmit(NAME_SELECT_GROUPS.goals, goal.goalId, !!goal.completedAt, goal.isArchived)) return [];
    return mapExists(goal.objectives, (objective, j) => {
      if (
        !objective.metric ||
        shouldOmit(NAME_SELECT_GROUPS.objectives, objective.objectiveId, !!objective.completedAt, objective.isArchived)
      )
        return;
      return {
        leftContent: (
          <ObjectiveTitleContent
            objective={objective}
            objectiveIndex={j + 1}
            showGoals={showGoals}
            goal={goal}
            goalIndex={i + 1}
          />
        ),
        rightContent: <MetricEntryContent objective={objective} metric={objective.metric} />,
      };
    });
  });

  return (
    <Stack width="100%">
      <FullGrid
        container
        alignItems="center"
        px={2}
        py={1}
        sx={{ backgroundColor: "surface.light", borderWidth: "0 0 1px 0", borderColor: "grey.300" }}
      >
        <Grid2 size={{ xs: 6 }}>
          <Stack direction="row" gap={1.5} alignItems="center">
            <EventIcon sx={{ fontSize: "20px", color: "text.secondary" }} />
            <Typography variant="body1">
              {progress.appointment.startTime.setZone(timezone).toLocaleString(DateTime.DATETIME_FULL)}
            </Typography>
            <Typography variant="body1" sx={{ color: "text.secondary" }}>
              {progress.appointment.title}
            </Typography>
          </Stack>
        </Grid2>
        <Grid2 size={{ xs: 6 }} pl={2}>
          <Chip
            label={
              <Stack direction="row" gap={1} alignItems="center">
                <AccountCircleIcon sx={{ fontSize: "18px", color: "text.secondary" }} />
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {progress.appointment.providerName}
                </Typography>
              </Stack>
            }
            sx={{ borderRadius: 1 }}
          />
        </Grid2>
      </FullGrid>

      {isEmpty(objectiveRowProps) && !progress.note && !progress.groupNote ? (
        <AppointmentEntryRow
          leftContent={
            <Typography variant="body1" fontStyle="italic">
              No Data
            </Typography>
          }
          borderBottom={0}
        />
      ) : (
        <>
          {progress.note && (
            <AppointmentEntryRow
              leftContent={<Typography variant="h3">Student Notes</Typography>}
              rightContent={<Typography variant="body1">{progress.note}</Typography>}
            />
          )}
          {progress.groupNote && (
            <AppointmentEntryRow
              leftContent={<Typography variant="h3">Group Notes</Typography>}
              rightContent={<Typography variant="body1">{progress.groupNote}</Typography>}
            />
          )}
          {objectiveRowProps.map((props, i) => (
            <AppointmentEntryRow {...props} borderBottom={i === objectiveRowProps.length - 1 ? 0 : 1} key={i} />
          ))}
        </>
      )}
    </Stack>
  );
};

const logger = initLogger("StudentProgressReport", getLoggerContext);

const StudentProgressReport = ({ studentId }: { studentId: string }) => {
  const {
    apiStore: { progressApi },
    authStore: { timezone },
  } = useContext(StoreContext);

  const currentDate = toLocalDate(DateTime.now(), timezone);
  const [timeRange, setTimeRange] = useState<Partial<DateTimeRange>>({
    startTime: currentDate.minus({ days: 7 }),
    endTime: currentDate,
  });
  const isTimeRangeValid = timeRange.startTime && timeRange.endTime;

  const [itemFilter, setItemFilter] = useState<SelectOption | null>(null);
  const [providerFilter, setProviderFilter] = useState<SelectOption | null>(null);
  const [filterAnchor, setFilterAnchor] = useState<HTMLElement>();
  const [showGoals, setShowGoals] = useState(true);
  const [showArchived, setShowArchived] = useState(false);
  const [showCompleted, setShowCompleted] = useState(true);
  const [pageState, setPageState] = useState<PaginateState>({ pageSize: 20, offset: 0 });

  const [progressPage, setProgressPage] = useState<PaginatedResult<SingleStudentAppointmentProgress>>();
  useEffect(() => {
    if (!isTimeRangeValid) return;
    progressApi
      .searchStudentProgress(studentId, {
        startTime: timeRange.startTime,
        endTime: timeRange.endTime?.plus({ days: 1 }),
        providerId: providerFilter?.key,
        ...pageState,
      })
      .then(page => {
        setProgressPage(page);
        if (page.totalCount < pageState.offset) setPageState({ pageSize: 20, offset: 0 });
      })
      .catch(logger.handleFailure("searchStudentProgress"));
  }, [timeRange, providerFilter, isTimeRangeValid, pageState]);

  let content = isTimeRangeValid ? (
    <LoadingScreen />
  ) : (
    <Typography variant="body1" p={2}>
      Select a valid time range above
    </Typography>
  );

  let nameOptions: SelectOption[] = [];
  let providerOptions: SelectOption[] = [];

  if (progressPage) {
    if (progressPage.totalCount === 0) {
      content = (
        <FullStack width="100%" alignItems="center" role="region" aria-label="progress-list">
          <Typography pt={4}>No Data Found</Typography>
        </FullStack>
      );
    } else {
      content = (
        <FullStack gap={1} role="region" aria-label="progress-list">
          {progressPage.records.map(progress => (
            <AppointmentEntry
              progress={progress}
              itemFilter={itemFilter}
              showGoals={showGoals}
              showCompleted={showCompleted}
              showArchived={showArchived}
              key={progress.appointment.id}
            />
          ))}
        </FullStack>
      );

      const allNameOptions = progressPage.records.flatMap(progress =>
        progress.goals.flatMap(goal => [
          { key: goal.goalId, label: goal.description, value: goal.goalId, groupName: NAME_SELECT_GROUPS.goals },
          ...goal.objectives.map(objective => ({
            key: objective.objectiveId,
            label: objective.description,
            groupName: NAME_SELECT_GROUPS.objectives,
          })),
        ]),
      );
      const uniqueNameOptions = groupBy(uniqBy(allNameOptions, "key"), "groupName");
      const goals = uniqueNameOptions[NAME_SELECT_GROUPS.goals] || [];
      const objectives = uniqueNameOptions[NAME_SELECT_GROUPS.objectives] || [];
      nameOptions = [...goals, ...objectives];

      providerOptions = uniqBy(
        progressPage.records.flatMap(
          record =>
            ({
              key: record.appointment.providerId,
              label: record.appointment.providerName,
            }) as SelectOption,
        ),
        "key",
      ).sort((a, b) => a.label.localeCompare(b.label));
    }
  }

  return (
    <FullStack pt={2}>
      <Stack pb={2} direction="row" alignItems="center" justifyContent="space-between">
        <AutoCompleteInput
          label="Search ..."
          options={nameOptions}
          selected={itemFilter}
          onSelect={setItemFilter}
          size="small"
          width={400}
        />
        <Stack gap={2} direction="row" alignItems="center">
          <AutoCompleteInput
            label="Provider"
            options={providerOptions}
            selected={providerFilter}
            onSelect={setProviderFilter}
            size="small"
            width={240}
          />
          <DateRangePicker
            label="Date Range"
            value={[timeRange.startTime || null, timeRange.endTime || null]}
            onChange={range =>
              setTimeRange({
                startTime: range[0] ? toLocalDate(range[0], timezone) : undefined,
                endTime: range[1] ? toLocalDate(range[1], timezone) : undefined,
              })
            }
            slots={{ field: SingleInputDateRangeField }}
            sx={{ width: 240 }}
            slotProps={{ textField: { size: "small" } }}
          />
          <Button startIcon={<SettingsIcon />} onClick={e => setFilterAnchor(e.currentTarget)}>
            <Typography variant="body1" fontWeight="500">
              Settings
            </Typography>
          </Button>
          <StyledMenu anchorEl={filterAnchor} open={!!filterAnchor} onClose={() => setFilterAnchor(undefined)}>
            <ToggleMenuItem
              toggle={() => setShowGoals(!showGoals)}
              isEnabled={showGoals}
              text="Show Goals"
              key="show-goals"
            />
            <ToggleMenuItem
              toggle={() => setShowArchived(!showArchived)}
              isEnabled={showArchived}
              text="Show Archived"
              key="show-archived"
            />
            <ToggleMenuItem
              toggle={() => setShowCompleted(!showCompleted)}
              isEnabled={showCompleted}
              text="Show Achieved"
              key="show-achieved"
            />
          </StyledMenu>
        </Stack>
      </Stack>

      <Box
        sx={{
          width: "100%",
          flex: "1 1 0%",
          overflowY: "auto",
          borderRadius: "4px 0 0 4px",
          borderWidth: "1px 1px 0 1px",
          borderColor: "grey.300",
        }}
      >
        {content}
      </Box>

      {progressPage && (
        <Box
          sx={{
            width: "100%",
            borderRadius: "0 4px 4px 0",
            borderWidth: "0 1px 1px 1px",
            borderColor: "grey.300",
          }}
        >
          <PaginationControl pageState={pageState} setPageState={setPageState} totalCount={progressPage.totalCount} />
        </Box>
      )}
    </FullStack>
  );
};

export default StudentProgressReport;
