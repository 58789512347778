import { Editor, JSONContent, Mark, mergeAttributes } from "@tiptap/react";
import { get, isEmpty, map } from "lodash";
import { mapExists } from "@parallel/vertex/util/collection.util";

const pillStyles = {
  border: "1px solid lightgrey",
  "border-radius": "10px",
  padding: "2px 6px",
  "font-size": "0.75rem",
  "vertical-align": "1px",
  "white-space": "nowrap",
};

const getPillStyleString = (attributes: Record<string, any>) => {
  const styles = attributes.isMissing ? { ...pillStyles, border: "1px solid red" } : pillStyles;
  return map(styles, (value, key) => `${key}: ${value};`).join(" ");
};

export const InjectedValueMark = Mark.create({
  name: "injectedValue",

  addAttributes() {
    return {
      tokenPath: {
        default: null,
        parseHTML: element => element.getAttribute("data-tokenpath"),
      },
      isMissing: {
        default: false,
        parseHTML: element => element.classList.contains("missing"),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "span",
        getAttrs: node => node.classList.contains("injected") && null,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["span", mergeAttributes({ style: getPillStyleString(HTMLAttributes) }, HTMLAttributes), 0];
  },
});

const getTextContentString = ({ type, text, marks }: JSONContent) => {
  if (type !== "text" || !text) return;

  // TODO handle multiple marks?
  const firstMark = marks && marks[0];

  switch (firstMark?.type) {
    case "injectedValue":
      const injectedTokenPath = firstMark.attrs?.tokenPath;
      return injectedTokenPath ? `{{ ${injectedTokenPath} }}` : text;
    case "bold":
      return `<strong>${text}</strong>`;
    case "italic":
      return `<em>${text}</em>`;
    case "underline":
      return `<u>${text}</u>`;
    default:
      return text;
  }
};

const getParagraphContentString = (content?: JSONContent[]): string => {
  const strings = mapExists(content, getTextContentString);
  return strings.join("");
};

const getNodeContentHtmlString = (node: JSONContent): string | null => {
  switch (node.type) {
    case "paragraph":
      const paragraphChildren = getParagraphContentString(node.content);
      return `<p>${paragraphChildren}</p>`;
    case "listItem":
      const firstChild = get(node.content, 0);
      const itemContent =
        firstChild?.type === "paragraph"
          ? getParagraphContentString(firstChild.content)
          : getParagraphContentString(node.content);
      return `<li>${itemContent}</li>`;
    case "bulletList":
      const bulletListChildren = mapExists(node.content, getNodeContentHtmlString);
      return `<ul>${bulletListChildren.join("\n")}</ul>`;
    case "orderedList":
      const orderedListChildren = mapExists(node.content, getNodeContentHtmlString);
      return `<ol>${orderedListChildren.join("\n")}</ol>`;
    default:
      return null;
  }
};

export const getContentHtmlString = (editor: Editor): string | null => {
  const { content: docContent } = editor.getJSON();
  if (!docContent || isEmpty(docContent)) return null;

  if (docContent[0].type === "paragraph") {
    const paragraphs = mapExists(docContent, getNodeContentHtmlString);
    return paragraphs.join("\n");
  }

  return docContent.map(getNodeContentHtmlString).join("");
};
